import React, { useRef, useEffect, useState } from 'react'
import { getIn } from 'formik'
import { FieldArray } from 'formik'
import PropTypes from 'prop-types'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy, useSortable, sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import classNames from 'classnames'

import FieldComponent from '../FieldComponent'
import { Button } from '../../../ui/Button'
import { valueFormat, reorder } from '../../../../utils'
import Label from './Label'
import useWatched from './useWatched'


const Div = ({ children, id }) => (<div id={id} className="fieldarray">{children}</div>)
const FieldSet = ({ children }) => (<fieldset className="input-group">{children}</fieldset>)


Div.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node
  ]),
  id: PropTypes.string
}

FieldSet.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node
  ])
}

const DragHandle = listeners => <div {...listeners} className="btn btn-none btn-drag btn-icon-only"><i className="dz-handle" /></div>

const SortableElement = ({ children, id, sortable, sortId }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform
  } = useSortable({ id: sortId })
  const style = {
    transform: CSS.Translate.toString(transform)
  }
  return (
    <fieldset
      {...attributes}
      ref={setNodeRef}
      id={id}
      style={style}
      className="input-group"
    >
      {sortable ? <DragHandle {...listeners} /> : null }
      {children}
    </fieldset>
  )
}

SortableElement.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node
  ]),
  id: PropTypes.string,
  sortable: PropTypes.bool,
  sortId: PropTypes.string
}

const FieldArrayInput = props => {
  const template = useRef({})
  const watched = useWatched(props, { fields: props.fields }, props.multi)
  const { required, allowdelete, max, sortable, showLabel, label, id, nobuttons, disabled,
    inlinedelete, inlineadd } = props
  const { name, value } = props.field
  const next = (!value || (Array.isArray(value) && !value.length)) ? 0 : value.length + 1

  useEffect(() => {
    props.fields.forEach(field => { template.current[field.name] = getIn(field, 'defaultvalue', null) })
    if (!props.field.value || (Array.isArray(props.field.value) && !props.field.value.length)) {
      if (props.required) {
        // If the field is required, show fields by default
        props.form.setFieldValue(props.field.name, [ template.current ], false)
      }
    }
  }, [])

  const renderArrayFields = (idx, insert, remove) => {
    const { errors, values } = props.form
    const outer = Array.isArray(watched[idx]) ? watched[idx].filter(field => {
      if (props.show_required) { return field._edit === 'true' && field._required === 'true' }
      if (props.required) { return field._edit === 'true' && field._required === 'true' }
      return field._edit === 'true'
    }) : watched.filter(field => {
      if (props.show_required) { return field._edit === 'true' && field._required === 'true' }
      if (props.required) { return field._edit === 'true' && field._required === 'true' }
      return field._edit === 'true'
    })
    let current_group = []
    let count = 12
    if (outer) {
      const outer_hidden = outer.filter(field => field.input === 'Hidden').map((field, fidx) => (
        <FieldComponent {...props} key={`fc-${props.field.name}-${idx}-${fidx}`} field={field} errors={errors} />
      ))
      const outer_visible = outer.filter(field => field.input !== 'Hidden').map((field, fidx) => {
        if (field.name.indexOf('.price_per') > -1) {
          field.label = `Price Per ${valueFormat('unit', values.floor_size_measurement_type)}`
        }
        if (props.input === 'TranslatableTextArea') {
          if (field.name.indexOf('language') > -1 && field.input === 'Select') {
            const already_selected = getIn(props.form.values, props.field.name, []).map(v => v.language)
            const options = props.settings.meta.allowed_translations
              .map(lang => ({ value: lang.code, label: lang.name, isDisabled: already_selected.includes(lang.code) }))
            field.options = options
          }
          if (field.name.indexOf('content') > -1 && field.input === 'TextArea') {
            const language = getIn(props.form.values, field.name.replace('.content', '.language'), {})
            if (language) {
              const option = props.settings.meta.allowed_translations
                .find(lang => language === lang.code)
              field.label = field.label.replace('Translated', option.name)
              field.lang = option.code
              if (option.rtl) {
                field.rtl = true
              }
            }
          }
        }
        return (
          <div
            key={`field-${props.field.name}-${idx}-${fidx}`}
            className={classNames('field', { error: (field.name in errors), required: field._required === 'true' }, field.cols ? `col-${field.cols}` : '')}
          >
            <FieldComponent {...props} key={`fc-${props.field.name}-${idx}-${fidx}`} field={field} errors={errors} />
          </div>
        )
      })
      const row_buttons = []
      if (props.multi) {
        // eslint-disable-next-line no-console
        if (
          ((value && value.length > 0) || (value && required && value.length > 1))
          && allowdelete !== false && inlinedelete) {
          row_buttons.push(<Button icon="#icon16-Bin" type="button" className="btn btn-grey btn-icon-16 btn-icon-only input-group-addon" onClick={() => {
            remove(idx)
          }} />)
        }
        if (
          ((!value && value.length === 0) || (value && idx === value.length - 1 && (max ? value.length < max : true)))
        && inlineadd) {
          row_buttons.push(
            <Button icon="#icon16-Plus" type="button" className="btn btn-grey btn-icon-16 btn-icon-only input-group-addon" onClick={() => {
              insert(!Array.isArray(value) ? 0 : value.length + 1, {})
            }} />
          )
        }
      }
      const groups = [ ...outer_hidden ]
      outer_visible.forEach((node, fidx) => {
        const classes = node.props && node.props.className ? node.props.className : ''
        const regex = /col(-[a-z]+)?-(\d+)/ig
        const matches = regex.exec(classes)
        current_group.push(node)
        if (matches) {
          const col = parseInt(matches.pop(), 10)
          count -= col
        }
        if (count <= 0 || fidx === outer.length - 1) {
          groups.push(<div key={`col-${idx}-${fidx}`} className='input-group'>{current_group}{row_buttons}</div>)
          current_group = []
          count = 12
        }
      })
      return groups
    }
    return null
  }

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  const [ items, setItems ] = useState([])
  useEffect(() => {
    if (value && Array.isArray(value)) {
      setItems(value.map(
        (v, vid) => ({
          ...v,
          id: v.id ? v.id : `fieldarray-${vid}`
        }))
      )
    }
  }, [ value?.length ])

  return (
    <FieldArray
      name={name}
      render={({ insert, remove, move }) => {
        const onDragEnd = event => {
          if (!event.active || !event.over) { return }
          const oldIndex = event.active.data.current.sortable.index
          const newIndex = event.over.data.current.sortable.index
          move(oldIndex, newIndex)
          setItems(reorder(items, oldIndex, newIndex))
        }
        return (
          <div id={id || name} className='fieldarray'>
            {(showLabel) && label ? (
              <Label className="formlabel fieldarray-label">
                {label}
              </Label>
            ) : null}
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              modifiers={[ restrictToVerticalAxis ]}
              onDragEnd={onDragEnd}
            >
              <SortableContext
                strategy={verticalListSortingStrategy}
                items={items}
              >
                {(!value || (Array.isArray(value) && !value.length)) && props.multi && !nobuttons && !disabled ? (
                  <div className="arrayaction">
                    <Button icon="#icon16-Plus" type="button" className="btn btn-grey btn-icon-16 btn-icon-left" onClick={() => {
                      insert(next, {})
                    }}>Add{props.text_suffix ? ` ${props.text_suffix}` : ''}</Button>
                  </div>
                ) : null}
                {props.multi ? (
                  items?.map((item, idx) => (
                    <SortableElement
                      sortable={sortable}
                      sortId={item.id}
                      key={item.id}
                      index={idx}
                    >
                      <div className="input-group">
                        {renderArrayFields(idx, insert, remove)}
                      </div>
                      {(props.multi && !nobuttons) ? (
                        <div className="arrayaction col-lg-12">
                          {(
                            (!value && value.length === 0)
                            || (value && idx === value.length - 1 && (max ? value.length < max : true))
                          )
                            && !inlineadd ? (
                              <Button icon="#icon16-Plus" type="button" className="btn btn-grey btn-icon-16 btn-icon-left" onClick={() => {
                                insert(!Array.isArray(value) ? 0 : value.length + 1, {})
                              }}>Add{props.text_suffix ? ` ${props.text_suffix}` : ''}</Button>
                            ) : null}
                          {(
                            value && (required && value.length === 1 || allowdelete === false))
                            || inlinedelete ? null : (
                              <Button icon="#icon16-X-Large" type="button" className="btn btn-grey btn-icon-16 btn-icon-left" onClick={() => {
                                remove(idx)
                              }}>Remove{props.text_suffix ? ` ${props.text_suffix}` : ''}</Button>
                            )}
                        </div>
                      ) : null}
                    </SortableElement>
                  ))
                ) : (
                  renderArrayFields(0)
                )}
                {}
              </SortableContext>
            </DndContext>
          </div>
        )
      }}
    />
  )
}

FieldArrayInput.propTypes = {
  id: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  classes: PropTypes.string,
  text_suffix: PropTypes.string,
  input: PropTypes.string,
  cache: PropTypes.object,
  settings: PropTypes.object,
  dragEnded: PropTypes.func,
  fields: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  allowdelete: PropTypes.bool,
  sortable: PropTypes.bool,
  copy: PropTypes.string,
  show_required: PropTypes.bool,
  showLabel: PropTypes.bool,
  label: PropTypes.string,
  multi: PropTypes.bool,
  nobuttons: PropTypes.bool,
  max: PropTypes.number,
  inlinedelete: PropTypes.bool,
  inlineadd: PropTypes.bool
}

export default FieldArrayInput
