import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { getIn } from 'formik'
import merge from 'deepmerge'
import { default as schedule_config } from '../../config/agent-schedule.json'
import { default as availability_config } from '../../config/agent-availability.json'
import { hasPermission } from '../../utils'
import Card from './Card'
import CustomForm from './forms/CustomForm'
import { Button } from './../ui/Button'
import FieldGroup from './forms/FieldGroup'


const AgentSchedule = props => {
  const [ initialScheduleValues, setInitialScheduleValues ] = useState()

  useEffect(() => {
    let initvals = {}
    schedule_config.config.fields.forEach(f => {
      initvals[f.name] = f.defaultvalue
    })
    new Promise((resolve, reject) => props.actions.fetchOne('agent-schedule', props.model.id, resolve, reject)).then(delta => {
      const saved_schedule = getIn(delta, `agent-schedule.${props.model.id}`) || {}
      if (saved_schedule.id) {
        initvals = merge(initvals, saved_schedule)
      }
    }).catch(e => {
      console.error(e)
    }).finally(() => {
      setInitialScheduleValues(initvals)
    })
  }, [])

  const submitSchedule = (values, formikBag) => {
    // eslint-disable-next-line no-new
    new Promise((resolve, reject) => {
      const params = {
        endpoint: schedule_config.config.endpoint,
        id: props.model.id,
        values: {
          ...values,
          modelname: 'agent-schedule'
        },
        resolve,
        reject
      }
      if (values.id) {
        return props.actions.updateModel(params)
      }
      return props.actions.createModel(params)
    }).then(r => {
      formikBag.setFieldValue('id', r.id)
    }).catch(e => {
      console.error(e)
    }).finally(() => {
      formikBag.setSubmitting(false)
    })
  }

  if (!initialScheduleValues) { return null }

  return (
    <>
      <Formik
        initialValues={initialScheduleValues}
        validateOnChange={false}
        validateOnBlur={true}
        onSubmit={submitSchedule}
        enableReinitialize={false}
      >{ formik => (
          <Card
            background
            classes="nopadding"
            header={(
              <>
                <h3 className="flex-heading">Schedule period</h3>
                <div className="details-section-buttons">
                  <Button
                    id="schedule-submit"
                    tabIndex="-1"
                    type="button"
                    onClick={() => {
                      formik.submitForm()
                    }}
                    disabled={formik.isSubmitting}
                    className="btn btn-red btn-round"
                  >
                  Save
                  </Button>
                </div>
              </>)}
            body={(
              <CustomForm
                component={'div'}
                className={'interaction-form'}
                render={() => (
                  <FieldGroup
                    card={false}
                    config={schedule_config.config}
                    fields={schedule_config.config.fields}
                  />
                )}
              />
            )}
          />
        )}
      </Formik>
    </>
  )
}

AgentSchedule.propTypes = {
  actions: PropTypes.object,
  model: PropTypes.object,
  user: PropTypes.object
}


const Availability = props => {
  const [ initialAvailability, setInitialAvailability ] = useState()

  useEffect(() => {
    let initvals = {}
    availability_config.config.fields.forEach(f => {
      initvals[f.name] = f.defaultvalue
    })
    new Promise((resolve, reject) => {
      const values = {
        modelname: 'agent-availability',
        formmodel: 'agents',
        endpoint: availability_config.config.endpoint,
        id: props.model.id
      }
      return props.actions.fetchMany({ values, resolve, reject })
    }).then(r => {
      initvals = merge(initvals, { availability: r.options })
    }).catch(e => {
      console.error(e)
    }).finally(() => {
      setInitialAvailability(initvals)
    })
  }, [])

  const submitAvailability = (values, formikBag) => {
    // eslint-disable-next-line no-new
    new Promise((resolve, reject) => {
      const params = {
        endpoint: availability_config.config.endpoint,
        id: props.model.id,
        values: {
          ...values,
          modelname: 'agent-availability'
        },
        resolve,
        reject
      }
      if (values.id) {
        return props.actions.updateModel(params)
      }
      return props.actions.createModel(params)
    }).then(r => {
      formikBag.setFieldValue('availability', r)
    }).catch(e => {
      console.error(e)
    }).finally(() => {
      formikBag.setSubmitting(false)
    })
  }

  if (!initialAvailability) { return null }

  return (
    <>
      <Formik
        initialValues={initialAvailability}
        validateOnChange={false}
        validateOnBlur={true}
        onSubmit={submitAvailability}
        enableReinitialize={false}
      >{ formik => (
          <Card
            background
            classes="nopadding"
            header={(
              <>
                <h3 className="flex-heading">Availability Window</h3>
                <div className="details-section-buttons">
                  <Button
                    id="availability-submit"
                    tabIndex="-1"
                    type="button"
                    onClick={() => {
                      formik.submitForm()
                    }}
                    disabled={formik.isSubmitting}
                    className="btn btn-red btn-round"
                  >
                    Save
                  </Button>
                </div>
              </>)}
            body={(
              <CustomForm
                component={'div'}
                className={'interaction-form'}
                render={() => (
                  <FieldGroup
                    card={false}
                    config={availability_config.config}
                    fields={availability_config.config.fields}
                  />
                )}
              />
            )}
          />
        )}
      </Formik>
    </>
  )
}

Availability.propTypes = {
  actions: PropTypes.object,
  model: PropTypes.object
}


const AgentAvailability = props => {
  useEffect(() => {
    const has_perms = (hasPermission([ 'users_update_scheduling' ], props.user.permissions) && props.model.id === props.user.agent.id) || (hasPermission([ 'users_update_scheduling' ], props.user.permissions) && hasPermission([ 'users_update' ], props.user.permissions, props.model, props.user.agent.id))
    if (!has_perms) {
      props.actions.registerRedirect('details')
    }
  }, [])

  return (
    <>
      <AgentSchedule {...props} />
      <Availability {...props} />
    </>
  )
}

AgentAvailability.propTypes = {
  actions: PropTypes.object,
  model: PropTypes.object,
  user: PropTypes.object
}

export default AgentAvailability
