import PropTypes from 'prop-types'
import classNames from 'classnames'
import React, { Suspense, lazy, useMemo, useState, useRef, useEffect, useCallback } from 'react'
import { NavLink } from 'react-router-dom'
import { getIn } from 'formik'
import { dateFnsLocalizer, Navigate as navigate } from 'react-big-calendar'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import endOfWeek from 'date-fns/endOfWeek'
import getDay from 'date-fns/getDay'
import enZA from 'date-fns/locale/en-ZA'

import { valueFormat, useCustomCompareMemo, generateAddress, breakpoint, hasPermission } from '../../utils'
import { Button } from '../ui/Button'
import Loader from './Loader'
import Card from './Card'
import TooltipContext from './tooltips/TooltipContext'


const Calendar = lazy(() => import('react-big-calendar').then(mod => ({ default: mod.Calendar })))

const locales = {
  'en-ZA': enZA
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales
})

function ViewNamesGroup({ views: viewNames, view, messages, onView }) {
  return viewNames.length > 2 ? viewNames.map(name => (
    <button
      type="button"
      key={name}
      className={classNames({ 'rbc-active': view === name })}
      onClick={() => onView(name)}
    >
      {messages[name]}
    </button>
  )) : null
}
ViewNamesGroup.propTypes = {
  messages: PropTypes.object,
  onView: PropTypes.func,
  view: PropTypes.string,
  views: PropTypes.array
}

function CustomToolbar({
  // date, // available, but not used here
  label,
  localizer: { messages },
  onNavigate
}) {
  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group">
        <Button
          type="button"
          className="btn btn-subtle"
          onClick={() => onNavigate(navigate.TODAY)}
          aria-label={messages.today}
        >
          Today
        </Button>
      </span>

      <div className={classNames('rbc-toolbar-label')}>
        <div className="rbc-week-toolbar">
          <Button
            type="button"
            className="btn btn-grey btn-icon btn-icon-only input-group-addon btn-icon-16"
            icon="#icon16-ChevronLeft"
            onClick={() => onNavigate(navigate.PREVIOUS)}
            aria-label={messages.previous}
          ></Button>
          <span className="rbc-toolbar-label">{label}</span>
          <Button
            type="button"
            className="btn btn-grey btn-icon btn-icon-only input-group-addon btn-icon-16"
            icon="#icon16-ChevronRight"
            onClick={() => onNavigate(navigate.NEXT)}
            aria-label={messages.next}
          ></Button>
        </div>
      </div>
    </div>
  )
}

CustomToolbar.propTypes = {
  date: PropTypes.instanceOf(Date),
  label: PropTypes.string,
  localizer: PropTypes.object,
  messages: PropTypes.object,
  onNavigate: PropTypes.func,
  onView: PropTypes.func,
  view: PropTypes.string,
  views: PropTypes.array
}

function Event({ event }) {
  return (
    <span>{event.title}</span>
  )
}
Event.propTypes = {
  event: PropTypes.object
}

const AgentCalendar = props => {
  const { actions, model } = props

  const [ view, setView ] = useState(breakpoint.matches ? 'week' : 'agenda')

  const [ selected, setSelected ] = useState()

  const [ range, setRange ] = useState({
    start: startOfWeek(new Date()),
    end: endOfWeek(new Date())
  })

  // eslint-disable-next-line no-unused-vars
  const [ events, setEvents ] = useState([])

  const { components, formats } = useMemo(
    () => ({
      components: {
        event: Event,
        toolbar: CustomToolbar
      },
      formats: {
        eventTimeRangeFormat: () => '',
        agendaDateFormat: date => valueFormat('date', date),
        dayRangeHeaderFormat: date => `${valueFormat('date', date.start)} - ${valueFormat('date', date.end)}`,
        agendaHeaderFormat: date => `${valueFormat('date', date.start)} - ${valueFormat('date', date.end)}`
      }
    }),
    []
  )
  const clickRef = useRef(null)

  const toggleView = e => {
    if (e.matches) {
      setView('week')
    } else if (e.matches !== undefined) {
      setView('agenda')
    }
  }

  useEffect(() => {
    if (!hasPermission([ 'users_update_scheduling' ], props.user.permissions)) {
      props.actions.registerRedirect('details')
    }
    breakpoint.addEventListener('change', toggleView)
    return () => {
      breakpoint.removeEventListener('change', toggleView)
      window.clearTimeout(clickRef?.current)
    }
  }, [])

  useEffect(() => {
    new Promise((resolve, reject) => actions.fetchViewingFeedback({
      modelname: 'agent',
      modelid: model.id,
      action: 'interactions',
      params: {
        get_all: 1,
        communication__viewing_date__gte: valueFormat('shortdate', range.start),
        communication__viewing_date__lte: valueFormat('shortdate', range.end),
        order_by: 'communication__viewing_date',
        meta_fields: 'contact,lead,residential,commercial'
      },
      resolve,
      reject
    })).then(r => {
      setEvents(r.map(event => {
        const listing = getIn(event, 'meta.lead.meta.residential') || getIn(event, 'meta.lead.meta.commercial') || getIn(event, 'meta.lead.meta.project') || getIn(event, 'meta.lead.meta.holiday')
        const contact = getIn(event, 'meta.contact')
        const start = new Date(`${getIn(event, 'meta.communication.viewing_date')}T${getIn(event, 'meta.communication.viewing_start_time')}`)
        const end = new Date(`${getIn(event, 'meta.communication.viewing_date')}T${getIn(event, 'meta.communication.viewing_end_time')}`)
        return {
          id: event.id,
          title: `${listing.web_ref} - ${generateAddress(listing, null, false)}`,
          address: `${valueFormat('time', start)}-${valueFormat('time', end)} - ${listing.web_ref} - ${generateAddress(listing, null, true)}`,
          contact,
          lead: `/secure/${contact.site}/${listing.model}/${listing.id}/leads/${getIn(event, 'lead')}`,
          start,
          end
        }
      }))
    }).catch(e => {
      if (e.status !== 408) {
        console.error(e)
      }
    })
  }, [ useCustomCompareMemo(range) ])

  const onSelectEvent = useCallback((calEvent, event) => {
    /**
     * Here we are waiting 250 milliseconds (use what you want) prior to firing
     * our method. Why? Because both 'click' and 'doubleClick'
     * would fire, in the event of a 'doubleClick'. By doing
     * this, the 'click' handler is overridden by the 'doubleClick'
     * action.
     */
    setSelected(null)
    window.clearTimeout(clickRef?.current)
    clickRef.current = window.setTimeout(() => {
      if (view === 'week') {
        setSelected({ event: calEvent, target: event.target })
      }
    }, 250)
  }, [ view ])

  const onDoubleClickEvent = useCallback(calEvent => {
    /**
     * Notice our use of the same ref as above.
     */
    window.clearTimeout(clickRef?.current)
    clickRef.current = window.setTimeout(() => {
      actions.registerRedirect(calEvent.lead)
    }, 250)
  }, [ ])

  return (
    <Card
      background
      body={() => (
        <Suspense fallback={<Loader inline />}>
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            min={new Date(1972, 0, 1, 8, 0, 0, 0)}
            max={new Date(1972, 0, 1, 20, 0, 0, 0)}
            scrollToTime={new Date()}
            timeslots={1}
            onSelectEvent={onSelectEvent}
            onDoubleClickEvent={onDoubleClickEvent}
            onRangeChange={dates => {
              setSelected(null)
              setRange({ start: dates[0], end: dates[6] })
            }}
            formats={formats}
            view={view}
            onView={setView}
            views={{
              week: true,
              agenda: true
            }}
            components={components}
          />
          {selected ? (
            <TooltipContext
              options={{ followCursor: false, arrow: 'top-left', shiftY: -8 }}
              className=""
              title={(
                <>
                  {selected.event.address}
                  <Button onClick={() => setSelected(null)} type="button" className="btn btn-none btn-icon-only btn-icon-24" icon="#icon24-X-Small" />
                </>
              )}
              visible
              clickable
              containerRef={selected.target.closest('.rbc-event')}
              rootRef={selected.target.closest('.rbc-events-container')}
              content={(
                <>
                  <strong>{`${selected.event.contact?.first_name}${selected.event.contact?.last_name ? ` ${selected.event.contact?.last_name}` : ''}`}</strong> <span>{selected.event.contact?.cell_number}</span>
                  <Button component={NavLink} to={selected.event.lead} className="btn btn-round btn-red">View Lead</Button>
                </>
              )}
            />
          ) : null}
        </Suspense>
      )}
    />
  )
}

AgentCalendar.propTypes = {
  model: PropTypes.object,
  user: PropTypes.object,
  actions: PropTypes.object,
  config: PropTypes.object
}

export default AgentCalendar
