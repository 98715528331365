import { Field, Formik, getIn } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'

import { NavLink } from 'react-router-dom'
import { sortBy, uniqueArray } from '../../../../../utils'
import { Button } from '../../../../ui/Button'
import { Scrollbar } from '../../../../ui/Scrollbars'
import CustomForm from '../../../forms/CustomForm'
import TextInput from '../../../forms/inputs/Text'
import Loader from '../../../Loader'
import VersionSummary from './VersionSummary'


class VersionIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      leads: [],
      offset: 0
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    this.form.handleSubmit()
  }

  handleSubmit(values, formik) {
    const { actions, config, match } = this.props
    const { fetchMany } = actions
    formik.setSubmitting(true)
    new Promise((resolve, reject) => {
      this.setState({ searching: true })
      const params = {
        modelname: 'leads',
        conflicts: true,
        select: true,
        trigram: true,
        params: {
          offset: values.offset,
          meta_fields: [ 'agent', 'contact', 'profile' ],
          order_by: '-created',
          term: values.term,
          limit: 10,
          id__in: values.id__in
        }
      }
      params.params[config.modelname] = match.params.id
      fetchMany({ values: params, resolve, reject })
    }).then(r => {
      formik.setSubmitting(false)
      this.setState({
        searching: false,
        leads: sortBy(uniqueArray([ ...r.options, ...this.state.leads ], 'id'), 'created').reverse(),
        more: values.id__in ? this.state.more : r.hasMore,
        searched: !!values.term
      })
    }).catch(() => {
      formik.setSubmitting(false)
      this.setState({ searching: false })
    })
    this.form.setFieldValue('id__in', null)
  }

  render() {
    const { visible, match, model, user } = this.props
    const values = {
      lead_type: 'Buyer',
      branch: getIn(model, 'branch'),
      agent: getIn(model, 'agent'),
      listing_model: getIn(match, 'params.model')
    }
    values[getIn(match, 'params.model')] = getIn(match, 'params.id')
    let message = 'No versions here yet.'
    if (this.state.searched && !getIn(model, 'versions', []).length) {
      message = 'No versions found.'
    }
    if (this.state.searching && !getIn(model, 'versions', []).length) {
      message = <Loader inline />
    }
    return (
      <div className="leads-index">
        <Formik
          initialValues={{
            offset: 0
          }}
          validateOnChange={false}
          validateOnBlur={true}
          onSubmit={this.handleSubmit}
          enableReinitialize={true}
        >{ formik => {
            this.form = formik
            return visible ? (
              <CustomForm
                component={'div'}
                render={() => (
                  <div className="keyword-search" ref={el => { this.el = el }}>
                    <Field
                      component={TextInput}
                      name="term"
                      id="term"
                      placeholder="Keyword Search"
                      type="search"
                      form_el={this.el}
                      show_search
                      suffix={(
                        <Button
                          type="button" // This cannot be submit otherwise sibling form is submitted
                          icon="#icon24-Search"
                          onClick={formik.handleSubmit}
                          disabled={formik.isSubmitting}
                          className="btn btn-none input-group-addon btn-icon btn-icon-24"
                        />
                      )}
                    />
                    {this.state.searched ? (
                      <Button
                        id="keyword-search-btn"
                        tabIndex="-1"
                        type="button" // This cannot be submit otherwise sibling form is submitted
                        icon="#icon16-Refresh"
                        onClick={() => {
                          formik.resetForm({ term: null })
                          formik.handleSubmit()
                        }}
                        disabled={this.state.searching}
                        className="btn btn-subtle btn-icon-left btn-icon-right btn-icon btn-icon-16"
                      />
                    ) : null}
                    <Button
                      type="button" // This cannot be submit otherwise sibling form is submitted
                      icon="#icon16-Plus"
                      onClick={() => {
                        this.props.actions.toggleWideSidebar('show-lead-creator')
                      }}
                      disabled={formik.isSubmitting}
                      className="btn btn-round btn-red btn-icon-left btn-icon-16"
                    >New</Button>
                  </div>
                )}
              />
            ) : null
          }}
        </Formik>
        {visible ? (
          <div className="leads-index-list">
            <Scrollbar
              style={{ height: `calc(100vh - ${this.el?.getBoundingClientRect().top}px)` }}
              innerRef={el => { this.index = el }}
            >
              {model.meta.versions && model.meta.versions.length ? (
                <div className="leads-index-list-inner">
                  {model.meta.versions.map((version, vidx) => (
                    <VersionSummary
                      key={`version-${vidx}`}
                      version={version}
                      match={match}
                      model={model}
                      user={user}
                    />
                  ))}
                  {this.state.more &&
                  <div className="list-actions">
                    <Button
                      onClick={() => {
                        this.form.setFieldValue('offset', this.form.values.offset + 10)
                        this.form.submitForm()
                      }}
                      className="btn btn-grey"
                    >
                      Load More
                    </Button>
                  </div>
                  }
                </div>
              ) : (
                <div className="no-leads">
                  <div className="no-leads-wrapper">
                    {message}
                  </div>
                </div>
              )}
            </Scrollbar>
          </div>
        ) : (
          <div className="back-to-leads">
            <Button component={NavLink} to="./" type="button" className="btn btn-none btn-icon-16 btn-icon-left" icon="#icon16-ChevronLeft">Back to Versions</Button>
          </div>
        )}
      </div>
    )
  }
}

VersionIndex.propTypes = {
  leads: PropTypes.object,
  cache: PropTypes.object,
  config: PropTypes.object,
  modelid: PropTypes.number,
  model: PropTypes.object,
  user: PropTypes.object,
  match: PropTypes.object,
  actions: PropTypes.object,
  visible: PropTypes.bool,
  fetch: PropTypes.bool
}

export default VersionIndex
