/* eslint-disable new-cap */
import merge from 'deepmerge'
import { Formik, getIn, setIn } from 'formik'
import { DownloadManager } from 'pdfjs-dist/web/pdf_viewer.js'
import PropTypes from 'prop-types'
import React from 'react'
import isEqual from 'react-fast-compare'

import cfg from '../config/config.json'
import log from '../logging'
import { capitalize, groupBy, handleSubmitError, hasAddons, hasPermission, isConditional, overwriteMerge, mergeByProperty, parseURL, slugify, sortBy, uniqueArray, breakpoint } from '../utils'
import validate from '../validate'
import Activity from './common/Activity'
import Card from './common/Card'
import CustomForm from './common/forms/CustomForm'
import FieldGroup from './common/forms/FieldGroup'
import Loader from './common/Loader'
import ModelActions from './common/ModelActions'
import QueryBuilder from './common/QueryBuilder'
import WhatsAppButton from './common/WhatsAppButton'
import HorizontalTabs from './common/tabs/HorizontalTabs'
import Tab from './common/tabs/Tab'
import { Button } from './ui/Button'


class HTMLPreview extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      offset: 0,
      showActions: breakpoint.matches,
      initvals: {},
      scale: 1.0,
      default: null,
      pageNumber: 1,
      numPages: 0,
      rotate: null,
      zoom: 'auto',
      view: !breakpoint.matches ? 'Mobi' : 'Desktop',
      scrolling: false,
      highlight: false,
      document: null,
      width: null,
      height: null,
      loading: getIn(this.props, 'match.params.model') === 'pages' ? false : true,
      templates: [],
      template_id: props.template_id,
      all_fields: [],
      template_config: null,
      template_cache: {},
      sectioned_fields: {},
      main_fields: [],
      sections: [],
      preview: '',
      scrollHeight: 0,
      section: null
    }
    this.toggleActions = this.toggleActions.bind(this)
    this.setInitVals = this.setInitVals.bind(this)
    this.isConditional = isConditional.bind(this)
    this.hasPermission = this.hasPermission.bind(this)
    this.redirectSchema = this.redirectSchema.bind(this)
    this.checkSize = this.checkSize.bind(this)
    this.addVerb = this.addVerb.bind(this)
    this.downloadManager = new DownloadManager({
      disableCreateObjectURL: false
    })
    this.download = this.download.bind(this)
    this.fetchActivity = this.fetchActivity.bind(this)
    this.fetchTemplateConfigs = this.fetchTemplateConfigs.bind(this)
    this.handleSubmitError = handleSubmitError.bind(this)
    this.updateTemplates = this.updateTemplates.bind(this)
    this.findFields = this.findFields.bind(this)
    this.zoomLevels = [
      50,
      75,
      100,
      125,
      150,
      200,
      400
    ]
    this.getButtons = this.getButtons.bind(this)
    this.renderTabs = this.renderTabs.bind(this)
    this.renderGroups = this.renderGroups.bind(this)
    this.modelActionsSubmit = this.modelActionsSubmit.bind(this)
    this.timers = {}
    this.fetching = {}
  }

  componentDidMount() {
    this.props.actions.selectNone()
    if (!this.props.model && this.props.modelid) {
      this.props.actions.fetchOne(this.props.modelname, this.props.modelid, resp => {
        const model = getIn(resp, `${this.props.modelname}.${this.props.modelid}`)
        this.setState({ initvals: this.initModel(null, model) }, () => {
          this.fetchTemplateConfigs(this.state.initvals.template)
        })
      }, false, true)
    } else if (this.props.model && this.props.modelid) {
      this.setState({ initvals: this.initModel(null, this.props.model) }, () => {
        this.fetchTemplateConfigs(this.state.initvals.template)
      })
    }
    if (!this.hasPermission()) { // If we're not allowed to be here, redirect to designated redirect
      const seller_landlord_report_type = [ 'seller-feedback', 'landlord-feedback' ].includes(getIn(this.props, 'match.params.action'))
      const redirect = seller_landlord_report_type ? (
        parseURL(this.props.routeConfig.brochure.redirect, this.props.model)
      ) : (
        parseURL(this.props.routeConfig.edit.redirect, this.props.model)
      )
      this.props.actions.registerRedirect(redirect)
    }

    if (this.props.location.search) {
      const qs = new QueryBuilder(this.props.location.search)
      if (qs.hasParam('id__in')) {
        // eslint-disable-next-line no-console
        console.log('foo')
        const initial_ids = qs.getParam('id__in')
        // eslint-disable-next-line
        new Promise((resolve, reject) => {
          const values = {
            modelname: this.props.modelname,
            params: {
              id__in: initial_ids,
              get_all: true
            }
          }
          return this.props.actions.fetchMany({ values, resolve, reject })
        })
      }
    }
    breakpoint.addEventListener('change', this.toggleActions)
    window.addEventListener('resize', this.checkSize)
    this.findFields()
  }

  componentDidUpdate(prevProps, prevState) {
    const { location, modelname } = this.props
    let initial_ids = []
    let model = this.props.model
    let old_model = prevProps.model
    if (location.search) {
      const qs = new QueryBuilder(location.search)
      if (qs.hasParam('id__in')) { initial_ids = initial_ids.concat(qs.getParam('id__in')) }
      if (initial_ids.length) {
        model = this.props.cache[modelname][initial_ids[0]]
        old_model = prevProps.cache[modelname][initial_ids[0]]
      }
    }
    if (!this.hasPermission()) { // If we're not allowed to be here, redirect to designated redirect
      const seller_landlord_report_type = [ 'seller-feedback', 'landlord-feedback' ].includes(getIn(this.props, 'match.params.action'))
      const redirect = seller_landlord_report_type ? (
        parseURL(this.props.routeConfig.brochure.redirect, this.props.model)
      ) : (
        parseURL(getIn(this.props.routeConfig, `${getIn(this.props, 'match.params.action')}.redirect`), this.props.model)
      )
      this.props.actions.registerRedirect(redirect)
    }
    if (
      (!this.state.initvals && this.props.model)
      || (
        !isEqual(model, old_model)
      )
      || (
        !isEqual(this.state.templates, prevState.templates)
      )
      || (
        !isEqual(this.state.template_config, prevState.template_config)
      )
    ) {
      this.findFields()
    }
    if ([ 'model', 'id', 'site', 'action' ].some(k => !isEqual(this.props.match.params[k], prevProps.match.params[k]))) {
      this.setState({ initvals: this.initModel(null, model) }, () => {
        this.fetchTemplateConfigs(this.state.initvals.template)
      })
    }
    if ([ 'tab' ].some(k => !isEqual(this.props.match.params[k], prevProps.match.params[k]))) {
      this.setState({ initvals: this.initModel(null, this.form.values) })
    }
    const tab_el = document.querySelector('.html-preview-form')
    if (tab_el) {
      const offset = tab_el.getBoundingClientRect().top
      if (this.state.offset !== offset) {
        this.setState({ offset })
      }
    }
    const form_el = document.querySelector('.html-preview-form form')
    if (form_el) {
      if (this.state.scrollHeight !== form_el.scrollHeight) {
        this.setState({ scrollHeight: form_el.scrollHeight })
      }
    }

    if (
      this.state.initvals.template !== prevState.initvals.template ||
      (!this.state.template_config && this.state.initvals.template)
    ) {
      this.fetchTemplateConfigs(this.state.initvals.template)
    }

    if (this.state.view !== prevState.view) {
      setTimeout(() => {
        const obj = this.preview
        if (getIn(this.props, 'match.params.model') === 'pages') {
          this.setState({
            loading: false,
            width: this.state.view === 'Mobi' ? 320 : 1440,
            height: this.state.view === 'Mobi' ? 800 : 920
          })
        } else {
          this.setState({
            loading: false,
            width: obj.contentWindow.document.body.scrollWidth,
            height: obj.contentWindow.document.body.scrollHeight
          })
        }
      }, 100)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkSize)
    breakpoint.removeEventListener('change', this.toggleActions)
    Object.keys(this.timers).forEach(timer => {
      clearTimeout(this.timers[timer])
    })
  }

  updateTemplates(templates) {
    if (!isEqual(templates, this.state.templates)) {
      this.setState({ templates })
    }
  }

  setInitVals(values) {
    const { model } = this.props
    const { template_config } = this.state
    this.setState({
      initvals: this.initModel(template_config, { ...model, ...values })
    })
  }

  findFields() { // Finds fields in the merged configs
    let { template_config } = this.state
    const { modelname } = this.props
    const { fields: model_fields } = this.props.config
    if (!this.form) { return }
    // Merge field configs from model fields and brochure fields.
    // Deepmerge wasn't doing this correctly.
    if (!template_config) {
      template_config = { fields: [] }
    }
    const all_fields = merge([], model_fields, { arrayMerge: overwriteMerge }).map(f => {
      const override = template_config.fields.find(bf => bf.name === f.name)
      if (override) { return merge(f, override, { arrayMerge: overwriteMerge }) }
      return f
    })
    const section_fields = template_config.fields.filter(f => f.section)
    const default_fields = all_fields.filter(f => f.edit)
    const main_fields = modelname === 'seller-feedback' ? template_config.fields.filter(f => !f.section) : [ ...template_config.fields.filter(f => !f.section), ...default_fields ]

    const sectioned_fields = groupBy(section_fields, 'section')
    const sections = Object.keys(sectioned_fields).map(s => ({
      value: s,
      label: s
    }))
    Object.keys(sectioned_fields).map(section => {
      sectioned_fields[section] = [ ...[
        {
          name: 'section',
          label: 'Section',
          input: 'Select',
          defaultvalue: sections.length ? sections[0].value : null,
          options: sections,
          edit: true
        }
      ], ...sectioned_fields[section] ]
    })
    this.setState({
      sectioned_fields,
      main_fields,
      sections,
      all_fields: uniqueArray(main_fields, 'name'),
      initvals: this.initModel(template_config, merge({}, this.form.values))
    })
  }

  checkSize() {
    this.timers.resize = setTimeout(() => {
      const obj = this.preview
      if (getIn(this.props, 'match.params.model') === 'pages') {
        return
      }
      if (
        this.state.width !== obj.contentWindow.document.body.scrollWidth
        || this.state.height !== obj.contentWindow.document.body.scrollHeight
      ) {
        this.setState({
          width: obj.contentWindow.document.body.scrollWidth,
          height: Math.max(
            document.body.scrollHeight,
            obj.contentWindow.document.body.scrollHeight + 15
          )
        })
      }
    }, 150)
  }

  async download(event, id) {
    const { cache, modelname } = this.props
    const url = `${cfg.defaultState.app.apigw}/reports/seller-feedback/${cache[modelname][id].site}/${modelname}/${cache[modelname][id].uuid}/?download=1`
    const filename = `${cache[modelname][id].web_ref} - Seller Feedback.pdf`

    const downloadByUrl = blobUrl => {
      if (!window.open) {
        throw new Error('DownloadManager: "window.open()" is not supported.')
      }
      window.open(blobUrl)
    }

    downloadByUrl(url, filename)
  }

  fetchActivity(params = {}, resolve = null, reject = null) {
    const { modelname, id, ...other_params } = params
    const values = {
      modelname: modelname ? modelname : this.props.config.servicename,
      id: id ? id : this.props.modelid,
      resolve,
      reject,
      params: other_params
    }
    this.props.actions.fetchActivity(values)
  }

  fetchTemplateConfigs(template) {
    if (!template) { return }
    if (getIn(this.props, 'match.params.model') === 'pages') {
      if (!this.state.loading && this.form.values.domain) {
        new Promise((resolve, reject) => {
          this.setState({ loading: true })
          return this.props.actions.fetchPageTemplateConfig({
            params: {
              template,
              domain_id: this.form.values.domain
            },
            resolve,
            reject
          })
        }).then(r => {
          this.setState({ template_config: r }, () => {
            this.setState({ initvals: this.initModel(r, { ...this.form.values }), loading: false }, () => {
              this.findFields()
              this.form.validateForm().then(valid => {
                if (!Object.keys(valid).length) {
                  this.form.submitForm()
                }
              })
            })
          })
        }).catch(e => {
          console.error(e)
        })
      }
      return
    }
    if (getIn(this.props, 'match.params.model') === 'marketing-emails') {
      new Promise((resolve, reject) => this.props.actions.fetchTemplateConfig({
        endpoint: 'mail/api/v1/email-templates/parse/',
        params: {
          template
        },
        resolve,
        reject
      })
      ).then(r => {
        this.setState({ template_config: r }, () => {
          this.setState({ initvals: this.initModel(r, {
            ...this.form.values
          }) }, () => {
            this.findFields()
            this.form.validateForm().then(valid => {
              if (!Object.keys(valid).length) {
                this.form.submitForm()
              }
            })
          })
        })
      }).catch(e => {
        console.error(e)
      })
      return
    }
    if (getIn(this.props, 'match.params.model') === 'newsletter-templates') {
      const cached_templates = getIn(this.props.cache, 'newsletter-templates')
      const cached_template = Object.keys(cached_templates)
        .map(k => cached_templates[k])
        .find(t => t.name === template) || {}
      if (
        cached_template
        && (
          this.state.initvals.send_day !== cached_template.send_day
          || this.state.initvals.send_day_exact !== cached_template.send_day_exact
        )
      ) {
        this.setState({ initvals: this.initModel(null, {
          ...this.form.values,
          ...cached_template
        }) })
      }
      new Promise((resolve, reject) => this.props.actions.fetchTemplateConfig({
        endpoint: 'content/api/v1/newsletter-templates/parse/',
        params: {
          template
        },
        resolve,
        reject
      })
      ).then(r => {
        this.setState({ template_config: r }, () => {
          this.setState({ initvals: this.initModel(r, {
            ...this.form.values,
            send_day: cached_template.send_day,
            send_day_exact: cached_template.send_day_exact
          }) }, () => {
            this.findFields()
            this.form.validateForm().then(valid => {
              if (!Object.keys(valid).length) {
                this.form.submitForm()
              }
            })
          })
        })
      }).catch(e => {
        console.error(e)
      })
      return
    }
    new Promise((resolve, reject) => this.props.actions.fetchTemplateConfig({
      params: {
        template
      },
      resolve,
      reject
    })
    ).then(r => {
      this.setState({ template_config: r }, () => {
        this.setState({ initvals: this.initModel(r, { ...this.form.values }) }, () => {
          this.findFields()
          this.form.validateForm().then(valid => {
            if (!Object.keys(valid).length) {
              this.form.submitForm()
            }
          })
        })
      })
    }).catch(e => {
      console.error(e)
    })
  }

  toggleActions(e) {
    if (e.matches && !this.state.showActions) {
      this.setState({ showActions: true })
    } else if (e.matches !== undefined && this.state.showActions) {
      this.setState({ showActions: false })
    }
  }

  hasPermission() {
    const { model, user, config, addons } = this.props
    if (config.addons) {
      if (!hasAddons(config.addons, addons)) { return false } // Entire module is disabled
    }
    const seller_landlord_report_type = [ 'seller-feedback', 'landlord-feedback' ].includes(getIn(this.props, 'match.params.action'))
    const requiredPermissions = seller_landlord_report_type ? this.props.routeConfig.brochure.permissions : getIn(this.props, `routeConfig.${getIn(this.props, 'match.params.action')}.permissions`)
    if (user.permissions.includes('is_prop_data_user')) { return true }
    if (!requiredPermissions) { return true } // No permissions needed
    const hasEditOwnPermissions = requiredPermissions.filter(perm => perm.endsWith('_view_own'))
    const hasEditAllPermissions = requiredPermissions.filter(perm => perm.endsWith('_view'))
    if (this.props.model && this.props.modelid) {
      if (hasPermission(hasEditAllPermissions, user.permissions)) { return true }
      if (hasPermission(hasEditOwnPermissions, user.permissions)) {
        switch (config.modelname) {
          case 'residential':
          case 'commercial':
          case 'holiday':
            if (![ 'agent', 'agent_2', 'agent_3', 'agent_4' ].map(k => getIn(model, k)).some(v => v !== user.agent.id)) {
              return false
            }
            return true
          case 'users':
          case 'leads':
            if (model.agent !== user.agent.id) { return false }
            return true
          case 'contacts':
            if (model.introduction_agent !== user.agent.id) { return false }
            return true
          case 'subscribers':
            if (model.meta.introduction_agent !== user.agent.id) { return false }
            return true
          default:
            return true
        }
      }
    }
    if (hasPermission(requiredPermissions, user.permissions)) { return true } // Implicit permissions
    return false
  }

  initModel(template_config, values) { // Initialise model data for formik
    const { model, cache, sourcemodel, config, location, modelname, modelid } = this.props
    let initvals = values ? { ...values } : {}
    if ([ 'seller-feedback', 'landlord-feedback' ].includes(getIn(this.props, 'match.params.action'))) {
      initvals.template = 'seller-feedback'
    }
    if (!modelid && !model) {
      if (location.search) {
        const qs = new QueryBuilder(location.search)
        if (qs.hasParam('template')) {
          initvals.template = qs.getParam('template')
        }
      }
      initvals.status = 'Draft'
      if (sourcemodel) {
        if (sourcemodel.model === 'referrals') {
          initvals.branch = sourcemodel.recipient_branch
          initvals.agent = sourcemodel.recipient_agent
          initvals.model = sourcemodel.seller_listing_type.includes('Residential') ? 'residential' : 'commercial'
          initvals.lightstone_id = sourcemodel.seller_lightstone_id
          initvals.lightstone_data = sourcemodel.lightstone_data
          initvals.owner = sourcemodel.created_contact
          initvals.source_ref = `referral-${sourcemodel.id}`
        }
      } else {
        initvals.model = modelname
      }
      return initvals
    }
    if (!model) {
      return initvals
    }
    // Initialise template options
    const template = config.fields.find(f => f.name === 'template')
    const template_list = sortBy(Object.keys(this.state.template_cache).map(k => this.state.template_cache[k]), 'ordinal')
    let selected_template
    this.updateTemplates(uniqueArray(this.state.templates, 'value'))
    if (getIn(model, 'template') === 'seller-feedback') {
      selected_template = getIn(model, 'template')
    }
    if (this.state.initvals.template) {
      selected_template = this.state.initvals.template
    }
    if (getIn(template_config, 'name', getIn(template_list, '0.name'))) {
      selected_template = getIn(template_config, 'name', getIn(template_list, '0.name'))
    }
    if (!selected_template && this.state.templates.length) {
      selected_template = getIn(this.state.templates, '0.value')
    }
    let data = { ...model }
    let initial_ids = []
    if (location.search) {
      const qs = new QueryBuilder(location.search)
      if (qs.hasParam('id__in')) { initial_ids = initial_ids.concat(qs.getParam('id__in')) }
      if (initial_ids.length) {
        data = cache[modelname][initial_ids[0]]
      }
    }
    initvals = {
      template: selected_template,
      model: this.props.modelname,
      modelname: this.props.modelname,
      uuid: model?.uuid,
      ...data,
      ...values
    }
    if ([ 'seller-feedback', 'landlord-feedback' ].includes(getIn(this.props, 'match.params.action'))) {
      initvals.template = 'seller-feedback'
    }
    const brochure_config = this.state.template_config
    if (brochure_config) {
      if (template && initvals.template === template.defaultvalue && !this.state.template_config) {
        this.setState({ template_config: brochure_config })
      }
      this.state.all_fields.forEach(f => {
        const field_name = `${f.parent ? `${f.parent}.` : ''}${f.override ? 'override-' : ''}${!Array.isArray(f.name) ? f.name.replace('override-', '') : f.name}`
        let value = getIn(initvals, field_name, getIn(values, field_name.replace('override-', ''), getIn(initvals, field_name.replace('override-', '')))) || f.defaultvalue
        if (getIn(this.props, 'match.params.model') === 'agents' && field_name === 'override-branch') {
          value = getIn(initvals, 'display_branches.0', getIn(initvals, 'branches.0'))
        }
        initvals = setIn(initvals, field_name, value)
      })
      Object.keys(initvals).forEach(f => {
        const field = this.state.all_fields.find(fe => fe.name === f.replace('override-', ''))
        if (!field) { return }
        if ([ 'agent', 'agent_2', 'agent_3', 'agent_4' ].includes(field.name) && getIn(initvals, f) === 'default') {
          return
        }
        if (field && field.modelname && !field.config) {
          let params = {}
          if (field.extraparams && this.form) { // Used for form submission
            field.params = parseURL(
              field.extraparams,
              { ...model, ...this.form.values },
              this.props.user ? this.props.user.agent : false,
              this.props.user ? this.props.cache.settings[this.props.user.agent.site.id] : false,
              this.props.cache,
              0, // index
              field.extraparamslock
            )
            field.params = field.params.replace(/(default),?/gi, '')
            const qs = new QueryBuilder(field.params || {})
            params = qs.getAllArgs()
          }
          params[`${field.optionvalue ? field.optionvalue : 'id'}__in`] = Array.isArray(initvals[f]) ? initvals[f] : [ initvals[f] ]
        }
      })
    }
    if (modelname === 'valuations' && model.model) {
      initvals.model = model.model
      if (getIn(this.props, 'match.params.action') === 'duplicate') {
        const keys = Object.keys(initvals)
        const dedupes = config.fields.filter(f => f.dedupe && keys.includes(f.name)).map(f => f.name)
        dedupes.forEach(key => {
          if (key === 'status') {
            initvals[key] = 'Draft'
          } else {
            delete initvals[key]
          }
        })
      }
    }
    Object.keys(initvals).forEach(f => {
      const field = this.state.all_fields.find(fe => fe.name === f || fe.name === f.replace('override-', ''))
      if (!field) { return }
      if (field.override) {
        field.name = `override-${field.name.replace('override-', '')}`
      }
      if (field && field.multiple === false) {
        delete initvals[field.name]
      }
      if (field.override && [ 'agent', 'agent_2', 'agent_3', 'agent_4' ].includes(f.replace('override-', ''))) {
        const field_name = `${f.parent ? `${f.parent}.` : ''}${f.name}`
        initvals = setIn(initvals, field_name, 'default')
      }
      if (field.copyto && field.input !== 'Check' && getIn(initvals, field.name)) {
        initvals = setIn(initvals, field.copyto, getIn(initvals, field.name))
      }
    })
    if (this.state.section) {
      initvals.section = this.state.section
    }
    // Ensure we always use correct model id
    initvals.id = model.id
    return initvals
  }

  redirectSchema(schema) { this.setState({ redirect: schema }) }


  addVerb(bag, fields, group, gidx) {
    if (!fields) { return null }
    const { model, modelname } = this.props
    const { fields: model_fields } = this.props.config
    // Merge field configs from model fields and brochure fields.
    // Deepmerge wasn't doing this correctly.
    const brochure_config = this.state.template_config || { fields: [] }
    const all_fields = merge([], model_fields, { arrayMerge: overwriteMerge }).map(f => {
      const override = brochure_config.fields.find(bf => bf.name === f.name && isEqual(bf.edit, f.edit))
      if (override) { return merge(f, override, { arrayMerge: overwriteMerge }) }
      return f
    })
    fields.forEach(bf => {
      const exists = all_fields.find(f => bf.name === f.name)
      if (!exists) {
        all_fields.unshift(merge({}, bf))
      }
    })
    if (all_fields) {
      const brochure_fields = fields.map(field => {
        const props = {
          ...field,
          cols: field.col ? field.col : 'lg-12',
          quality: false,
          name: field.override ? `override-${field.name.replace('override-', '')}` : field.name
        }

        if ([ 'seller-feedback', 'landlord-feedback' ].includes(getIn(this.props, 'match.params.action')) && props.name === 'template') {
          props.value = 'seller-feedback'
        }

        if (getIn(props, 'config.endpoint.parse')) {
          props.config.endpoint.read = parseURL(props.config.endpoint.read, { ...model, modelname })
        }

        if (props.name === 'template' || props.copyto === 'template') {
          props.updateTemplates = this.updateTemplates
        }

        if (field.override && [ 'agent', 'agent_2', 'agent_3', 'agent_4' ].includes(field.name.replace('override-', ''))) {
          props.options = [
            {
              id: 'default',
              first_name: 'Default',
              last_name: 'Agent'
            }
          ]
          const extraparams = props.extraparams.split('&').map(p => {
            if (p.startsWith('id__in__not')) {
              const prop = p.split('=')[0]
              let vars = p.split('=:').pop().split('|')
              vars = vars.filter(v => getIn(bag.values, v, getIn(model, v)) && bag.values[v] !== 'default')
              vars = vars.join('|')
              if (vars) {
                p = [ prop, vars ].join('=:')
                return p
              }
              return null
            }
            return p
          }).join('&')
          props.extraparams = extraparams
        }
        if (field.copyto && field.input !== 'Check' && getIn(this.form, `values.${field.copyto}`) !== getIn(this.form, `values.${field.name}`)) {
          this.form.setFieldValue(field.copyto, getIn(this.form, `values.${field.name}`))
        }
        if (field.name === 'section' && !this.state.sections.length) {
          props.edit = false
        }
        return props
      })
      return <FieldGroup
        key={`fg-${group}`}
        form={bag}
        card={!!group}
        gidx={gidx}
        match={this.props.match}
        groupname={group}
        fields={brochure_fields}
        setInitVals={this.setInitVals}
      />
    }
    return null
  }

  getButtons() {
    const { model, config, match } = this.props
    const { template } = this.form.values
    let page
    if ([ 'seller-feedback', 'landlord-feedback' ].includes(getIn(match, 'params.action'))) {
      page = 'seller-feedback'
    } else if (getIn(match, 'params.model') === 'valuations') {
      page = 'standard-valuation'
    }
    if (!model) { return null }
    switch (page) {
      case 'seller-feedback':
        return {
          pdf: {
            label: 'Download PDF',
            menu: null,
            redirect: null,
            icon: '#icon16-Download',
            action: 'submitForm',
            args: {
              action: 'report',
              template: this.form.values.template,
              scope: 'selected'
            },
            className: 'btn-round btn-red'
          },
          email: getIn(model, 'meta.owners.0.email') ? {
            label: 'Email Report',
            menu: null,
            redirect: null,
            icon: '#icon16-Envelope',
            action: 'submitForm',
            className: 'btn-round btn-red'
          } : null,
          copy: {
            label: 'Copy Report Link',
            menu: null,
            redirect: null,
            icon: '#icon16-Hyperlink',
            action: 'submitForm',
            className: 'btn-round btn-red'
          },
          whatsapp: {
            label: 'WhatsApp',
            menu: null,
            redirect: null,
            icon: '#icon16-WhatsApp',
            action: 'submitForm',
            className: 'btn-round btn-red',
            // eslint-disable-next-line no-unused-vars
            render: ({ key, context, actions: buttonactions, button }) => {
              if (model) {
                const phone = getIn(model, 'meta.owners.0.cell_number')
                let apigw = cfg.defaultState.app.reports.dev
                const url = parseURL(config.endpoint.public, model)
                if (process.env.REACT_APP_ENV === 'staging' || process.env.REACT_APP_ENV === 'e2e') {
                  apigw = cfg.defaultState.app.reports.stage
                } else if (process.env.NODE_ENV === 'production') {
                  apigw = cfg.defaultState.app.reports.live
                }

                return (
                  <WhatsAppButton key={key} component={Button} type="button" resetButtonStyle={false} className="btn btn-icon-16 action navitem btn-icon-left copy btn-round btn-red" separator=" : " url={`${apigw}${url}`} phone={phone} title={`Seller Report - ${model.web_ref}`}>
                    <div className="icon"><svg viewBox="0 0 16 16"><use href={button.icon.includes('icon16') ? `/images/icons-16.svg${button.icon}` : `/images/icons-24.svg${button.icon}`} /></svg></div>WhatsApp
                  </WhatsAppButton>
                )
              }
              return null
            }
          }
        }
      case 'standard-valuation':
        return {
          pdf: [ 'Published' ].includes(getIn(model, 'status')) && !this.state.loading ? {
            label: 'Download PDF',
            menu: null,
            redirect: null,
            icon: '#icon16-Download',
            action: 'submitForm',
            args: {
              action: 'report',
              template: this.form.values.template,
              scope: 'selected'
            },
            className: 'btn-round btn-red'
          } : null,
          email: ([ 'Published' ].includes(getIn(model, 'status')) && getIn(model, 'meta.owner.email')) && !this.state.loading ? {
            label: 'Email Report',
            menu: null,
            redirect: null,
            icon: '#icon16-Envelope',
            action: 'submitForm',
            className: 'btn-round btn-red'
          } : null,
          copy: [ 'Published' ].includes(getIn(model, 'status')) && !this.state.loading ? {
            label: 'Copy Report Link',
            menu: null,
            redirect: null,
            icon: '#icon16-Hyperlink',
            action: 'submitForm',
            className: 'btn-round btn-red'
          } : null,
          whatsapp: [ 'Published' ].includes(getIn(model, 'status')) && !this.state.loading ? {
            label: 'WhatsApp',
            menu: null,
            redirect: null,
            icon: '#icon16-WhatsApp',
            action: 'submitForm',
            className: 'btn-round btn-red',
            // eslint-disable-next-line no-unused-vars
            render: ({ key, context, actions: buttonactions, button }) => {
              if (model) {
                const phone = getIn(model, 'meta.owner.cell_number')
                let apigw = cfg.defaultState.app.reports.dev
                const url = parseURL(config.endpoint.public, model)
                if (process.env.REACT_APP_ENV === 'staging' || process.env.REACT_APP_ENV === 'e2e') {
                  apigw = cfg.defaultState.app.reports.stage
                } else if (process.env.NODE_ENV === 'production') {
                  apigw = cfg.defaultState.app.reports.live
                }
                return (
                  <WhatsAppButton key={key} component={Button} type="button" resetButtonStyle={false} className="btn btn-icon-16 action navitem btn-icon-left copy btn-round btn-red" separator=" : " url={`${apigw}${url}`} phone={phone} title={`Valuation - ${model.lightstone_data.propertyDetails.streetNumber} ${model.lightstone_data.propertyDetails.streetName}, ${model.lightstone_data.propertyDetails.suburb}`}>
                    <div className="icon"><svg viewBox="0 0 16 16"><use href={button.icon.includes('icon16') ? `/images/icons-16.svg${button.icon}` : `/images/icons-24.svg${button.icon}`} /></svg></div>WhatsApp
                  </WhatsAppButton>
                )
              }
              return null
            }
          } : null,
          request_approval: [ 'Draft' ].includes(model.status) && !this.state.loading ? {
            label: 'Request Approval',
            menu: null,
            redirect: null,
            icon: '#icon16-List',
            action: 'submitForm',
            className: 'btn-round btn-red'
          } : null,
          approve: [ 'Approval Requested' ].includes(model.status) && !this.state.loading ? {
            label: 'Approve',
            menu: null,
            redirect: null,
            icon: '#icon16-Check',
            action: 'submitForm',
            className: 'btn-round btn-red',
            conditions: {
              visible: [
                [
                  {
                    condition: {
                      type: 'permissions',
                      permissions: [
                        'valuation_approve'
                      ]
                    }
                  }
                ]
              ]
            }
          } : null,
          decline: [ 'Approval Requested' ].includes(model.status) && !this.state.loading ? {
            label: 'Decline',
            menu: null,
            redirect: null,
            icon: '#icon16-X-Large',
            action: 'submitForm',
            className: 'btn-round btn-grey',
            conditions: {
              visible: [
                [
                  {
                    condition: {
                      type: 'permissions',
                      permissions: [
                        'valuation_approve'
                      ]
                    }
                  }
                ]
              ]
            }
          } : null,
          convert: [ 'Published' ].includes(model.status) && !this.state.loading ? {
            label: 'Convert',
            menu: null,
            redirect: null,
            icon: '#icon16-ArrowExpand',
            link: '/secure/:site/:model/add/?id=:id&model=valuations',
            className: 'btn-round btn-red'
          } : null
        }
      default: {
        if (template && template.includes('signature')) {
          return {
            email: {
              label: 'Email Signature to Me',
              menu: null,
              redirect: null,
              icon: '#icon16-Envelope',
              action: 'submitForm',
              args: {
                action: 'signature',
                template: this.form.values.template,
                scope: 'selected'
              },
              className: 'btn-white'
            },
            save: {
              label: 'Download Signature',
              menu: null,
              redirect: null,
              icon: '#icon16-Download',
              action: 'submitForm',
              args: {
                action: 'signature',
                template: this.form.values.template,
                scope: 'selected'
              },
              className: 'btn-round btn-red'
            }
          }
        }
        break
      }
    }
    return null
  }

  renderTabs (formik) {
    const { config, model, user, match, location, routeConfig, modelid, modelname, actions } = this.props
    const { template_config } = this.state
    const tabs = {}
    Object.keys(config.fieldgroups).forEach(g => {
      if (!tabs[config.fieldgroups[g].tab]) {
        tabs[config.fieldgroups[g].tab] = []
      }
      tabs[config.fieldgroups[g].tab].push({ ...config.fieldgroups[g], name: g })
    })
    const configfields = merge([], config.fields)
    const templatefields = template_config ? merge([], template_config.fields).filter(f => !f.section) : []
    const sectionfield = configfields.find(f => f.name === 'section')
    sectionfield.defaultvalue = this.state.sections.length ? getIn(this.state, 'section') || this.state.sections[0].value : null
    sectionfield.options = this.state.sections
    const sectionfields = (formik.values.section && getIn(this.state.sectioned_fields, formik.values.section)) ? (
      getIn(this.state.sectioned_fields, formik.values.section)
    ).map(f => {
      f.group = sectionfield.group
      return f
    }) : []
    const fields = merge(configfields, merge(templatefields, sectionfields, {
      arrayMerge: (destinationArray, sourceArray) => mergeByProperty(destinationArray, sourceArray, [ 'name', 'edit' ])
    }), {
      arrayMerge: (destinationArray, sourceArray) => mergeByProperty(destinationArray, sourceArray, [ 'name', 'edit' ])
    })

    return (
      <HorizontalTabs
        config={routeConfig}
        location={location}
        match={match}
        model={model}
        modelid={modelid}
        defaultTab={slugify(config.tabs[0])}
        user={{ permissions: user.permissions, agent: user.agent }}
      >
        { config.tabs.map((tab, tabidx) => (
          <Tab key={`tab-${tabidx}`} tab={slugify(tab)} noScroll label={tab}>
            {tabs[tab].map((group, gidx) => {
              const groupfields = fields.filter(f => f.group === group.name)
              return groupfields.length ? (
                <React.Fragment key={`rg-${gidx}`}>
                  {this.addVerb(formik, groupfields, group.name, gidx)}
                  <div className="force-update">
                    <Button className="btn btn-grey" type='button' onClick={() => {
                      formik.validateForm().then(a => {
                        // eslint-disable-next-line no-unused-vars
                        const { section, model: modelvalue, template, ...valid } = formik.values
                        if ((Object.keys(a).length === 0 && model) || modelvalue === 'pages') {
                          let values = {
                            modelname,
                            id: formik.values.id,
                            quiet: true
                          }
                          let page
                          if ([ 'seller-feedback', 'landlord-feedback' ].includes(getIn(match, 'params.action'))) {
                            page = 'seller-feedback'
                          }
                          if (page === 'seller-feedback') {
                            values.seller_feedback_config = valid.seller_feedback_config
                            if (values.seller_feedback_config.seller_feedback_config) {
                              delete values.seller_feedback_config.seller_feedback_config
                            }
                          } else {
                            values = { ...values, ...formik.values }
                          }
                          if (!template.includes('signatures') && !modelvalue === 'pages') {
                            new Promise((resolve, reject) => actions.updateModel({
                              noloader: true,
                              values,
                              resolve,
                              reject
                            })).then(r => new Promise((res, rej) => actions.fetchOne(
                              config.modelname,
                              r,
                              res,
                              rej
                            ))).then(() => {
                              formik.submitForm()
                            }).catch(err => this.handleSubmitError(err, formik, this.form))
                          } else {
                            formik.submitForm()
                          }
                        }
                      }).catch(f => {
                        this.handleSubmitError(f, formik, this.form)
                      })
                    }}>Update Preview</Button>
                  </div>
                </React.Fragment>
              ) : null
            })}
          </Tab>
        )
        ) }
      </HorizontalTabs>
    )
  }

  renderGroups(formik) {
    const { config, model, actions, modelname, match } = this.props
    const { template_config } = this.state
    const configfields = merge([], config.fields)
    const templatefields = template_config ? merge([], template_config.fields).filter(f => !f.section) : []
    let fields = []
    if (template_config && template_config.fields.find(f => f.section) && !getIn(match, 'params.action') !== 'add') {
      const sectionfield = configfields.find(f => f.name === 'section') || {
        name: 'section',
        label: 'Section',
        input: 'Select',
        group: 'Select a Section to Edit',
        options: [],
        edit: true
      }
      sectionfield.defaultvalue = this.state.sections.length ? getIn(this.state, 'section') || this.state.sections[0].value : null
      sectionfield.options = this.state.sections
      const sectionfields = (formik.values.section && getIn(this.state.sectioned_fields, formik.values.section)) ? (
        getIn(this.state.sectioned_fields, formik.values.section)
      ).map(f => {
        f.group = sectionfield.group
        return f
      }) : []
      fields = merge(configfields, merge(templatefields, sectionfields, {
        arrayMerge: (destinationArray, sourceArray) => mergeByProperty(destinationArray, sourceArray, [ 'name', 'edit' ])
      }), {
        arrayMerge: (destinationArray, sourceArray) => mergeByProperty(destinationArray, sourceArray, [ 'name', 'edit' ])
      })
    } else {
      fields = merge(configfields, templatefields, {
        arrayMerge: (destinationArray, sourceArray) => mergeByProperty(destinationArray, sourceArray, [ 'name', 'edit' ])
      })
    }
    return Object.keys(config.fieldgroups).map((group, gidx) => {
      const group_data = config.fieldgroups[group]
      const groupfields = fields.filter(f => f.group === group)
      let show_button = false
      if (group_data.button?.show) {
        if (isConditional(group_data.button, 'show', true, this.form)) {
          show_button = group_data.button
        }
      } else {
        show_button = group_data.button
      }
      return (
        <React.Fragment key={`rg-${group}`}>
          {this.addVerb(formik, groupfields, group, gidx)}
          {getIn(match, 'params.action') !== 'add' ? (
            <div className="force-update">
              {show_button?.action ? (
                <Button className="btn btn-grey" type='button' onClick={() => {
                  this.props.actions[group_data.button.action](formik)
                }}>{group_data.button.label}</Button>
              ) : null}
              {(show_button === false || show_button?.action) ? null : (
                <Button className="btn btn-grey" type='button' onClick={() => {
                  formik.validateForm().then(a => {
                    // eslint-disable-next-line no-unused-vars
                    const { section, model: modelvalue, template, ...valid } = formik.values
                    if (Object.keys(a).length === 0 && model) {
                      let values = {
                        modelname,
                        id: model.id,
                        quiet: true
                      }
                      let page
                      if ([ 'seller-feedback', 'landlord-feedback' ].includes(getIn(match, 'params.action'))) {
                        page = 'seller-feedback'
                      }
                      if (page === 'seller-feedback') {
                        values.seller_feedback_config = valid.seller_feedback_config
                        if (values.seller_feedback_config.seller_feedback_config) {
                          delete values.seller_feedback_config.seller_feedback_config
                        }
                      } else {
                        values = { ...values, ...formik.values }
                      }
                      if (!template.includes('signatures') && modelvalue !== 'pages') {
                        new Promise((resolve, reject) => actions.updateModel({
                          noloader: true,
                          values,
                          resolve,
                          reject
                        })).then(r => new Promise((res, rej) => actions.fetchOne(
                          config.modelname,
                          r,
                          res,
                          rej
                        ))).then(() => {
                          formik.submitForm()
                        }).catch(err => this.handleSubmitError(err, formik, this.form))
                      } else {
                        formik.submitForm()
                      }
                    } else {
                      formik.submitForm()
                    }
                  }).catch(f => {
                    this.handleSubmitError(f, formik, this.form)
                  })
                }}>Update Preview</Button>
              )}
            </div>
          ) : null}
        </React.Fragment>
      )
    })
  }

  modelActionsSubmit(btn) {
    const { config, actions, modelname, match } = this.props
    actions.setSubmitting = this.form.setSubmitting
    actions.setStatus = this.form.setStatus
    actions.setTouched = this.form.setTouched
    actions.setErrors = this.form.setErrors
    switch (btn.label) {
      case 'Download PDF': {
        // eslint-disable-next-line no-unused-vars
        const { section, uuid, model: modelvalue, template, ...valid } = this.form.values
        let values = {
          modelname,
          id: valid.id,
          quiet: true
        }
        let label = capitalize(getIn(this.state.templates.find(t => t.value === template), 'label'))
        let page
        if ([ 'seller-feedback', 'landlord-feedback' ].includes(getIn(match, 'params.action'))) {
          page = 'seller-feedback'
        }
        if (page === 'seller-feedback') {
          values.seller_feedback_config = valid.seller_feedback_config
          label = 'Seller Feedback'
        } else {
          values = {
            ...values,
            ...valid
          }
        }
        new Promise((resolve, reject) => actions.updateModel({
          noloader: true,
          values,
          resolve,
          reject
        })).then(() => {
          new Promise((resolve, reject) => {
            const params = {
              params: {
                modelname,
                id: valid.id,
                uuid: modelvalue.uuid || uuid
              },
              modelname,
              args: {
                action: 'report',
                template: template,
                model: modelname
              },
              model: modelname,
              label,
              callback: () => {},
              resolve,
              reject
            }
            return actions.exportData(params)
          }).catch(err => this.handleSubmitError(err, actions, this.form))
        }).catch(err => this.handleSubmitError(err, actions, this.form))
        break
      }
      case 'Download Signature': {
        // eslint-disable-next-line no-unused-vars
        const { section, model: modelvalue, template, id, ...valid } = this.form.values
        const parms = {}
        for (const [ key, value ] of Object.entries(valid)) {
          if (key.includes('override')) {
            parms[key] = value
          }
        }
        const { first_name, last_name } = {
          first_name: getIn(valid, 'override-first_name', getIn(valid, 'first_name')),
          last_name: getIn(valid, 'override-last_name', getIn(valid, 'last_name'))
        }
        new Promise((resolve, reject) => {
          const params = {
            params: { id, ...parms },
            modelname,
            args: {
              action: 'signature',
              template: template,
              format: 'zip',
              model: modelname
            },
            model: modelname,
            label: `${first_name} ${last_name} - ${capitalize(getIn(this.state.templates.find(t => t.value === template), 'label'))}`,
            callback: () => {},
            resolve,
            reject
          }
          return actions.exportData(params)
        }).catch(err => this.handleSubmitError(err, actions, this.form))
        break
      }
      case 'Email Signature to Me': {
        // eslint-disable-next-line no-unused-vars
        const { section, model: modelvalue, template, ...valid_values } = this.form.values
        new Promise((resolve, reject) => {
          this.form.setSubmitting(true)
          this.setState({ loading: true })
          const valid = {
            modelname,
            id: modelvalue.id,
            quiet: true,
            ...valid_values,
            recipient_email: valid_values.email,
            recipient_first_name: valid_values.first_name,
            template
          }
          const params = {
            ...valid,
            endpoint: {
              write: `${config.endpoint.preview}/email/`
            },
            resolve,
            reject
          }
          return actions.updateTemplatePreview(params)
        }).then(() => {
          // Notify of success
          this.props.actions.notifyUser({ title: 'Success!', body: `An email has been sent to ${valid_values.email}.`, type: 'success' })
          this.setState({ loading: false })
          this.form.setSubmitting(false)
        }).catch(e => {
          this.props.actions.notifyUser({ title: 'Error', body: 'There was an issue sending this email.', type: 'error' })
          this.form.setSubmitting(false)
          this.setState({ loading: false })
          log.error(e)
        })
        break
      }
      case 'Email Report': {
        // eslint-disable-next-line no-new
        new Promise((resolve, reject) => {
          const { template } = this.form.values
          const values = {
            template
          }
          let page
          if ([ 'seller-feedback', 'landlord-feedback' ].includes(getIn(match, 'params.action'))) {
            page = 'seller-feedback'
          } else if (getIn(match, 'params.model') === 'valuations') {
            page = 'standard-valuation'
          }
          if (page === 'seller-feedback') {
            values.owners = getIn(this.props.model, 'owners')
            values[this.props.model.model] = this.props.model.id
          } else if (page === 'standard-valuation') {
            values.owner = getIn(this.props.model, 'owner')
            values.id = this.props.model.id
            values.template = 'standard-valuation'
          } else {
            values.owner = getIn(this.props.model, 'owner')
            values.id = this.props.model.id
          }
          return actions.emailTemplate({
            values,
            resolve,
            reject
          })
        }).then(() => {
          this.props.actions.notifyUser({ title: 'Success!', body: 'An email has been sent to the owners.', type: 'success' })
        }).catch(() => {
          this.props.actions.notifyUser({ title: 'Error', body: 'There was an issue sending this email.', type: 'error' })
        })
        break
      }
      case 'Copy Report Link': {
        if (this.props.model) {
          let apigw = cfg.defaultState.app.reports.dev
          const url = parseURL(config.endpoint.public, this.props.model)
          if (process.env.REACT_APP_ENV === 'staging' || process.env.REACT_APP_ENV === 'e2e') {
            apigw = cfg.defaultState.app.reports.stage
          } else if (process.env.NODE_ENV === 'production') {
            apigw = cfg.defaultState.app.reports.live
          }
          navigator.clipboard.writeText(`${apigw}${url}`)
          this.props.actions.notifyUser({ title: 'Success!', body: 'Copied url to you clipboard.', type: 'success' })
        }
        break
      }
      case 'WhatsApp': {
        break
      }
      case 'Request Approval': {
        if (this.props.model) {
          new Promise((resolve, reject) => actions.updateModel({
            noloader: true,
            values: {
              modelname,
              id: this.props.model.id,
              lightstone_id: this.props.model.lightstone_id,
              lightstone_data: this.form.values.lightstone_data,
              status: 'Approval Requested'
            },
            resolve,
            reject
          })).then(r => new Promise((res, rej) => actions.fetchOne('valuations', r, res, rej))).then(resp => {
            const model = getIn(resp, `${this.props.modelname}.${this.props.modelid}`)
            this.setState({ initvals: this.initModel(null, model) })
          }).catch(err => this.handleSubmitError(err, actions, this.form))
        }
        break
      }
      case 'Approve': {
        if (this.props.model) {
          new Promise((resolve, reject) => actions.updateModel({
            noloader: true,
            values: {
              modelname,
              id: this.props.model.id,
              lightstone_id: this.props.model.lightstone_id,
              lightstone_data: this.form.values.lightstone_data,
              status: 'Published'
            },
            resolve,
            reject
          })).then(r => new Promise((res, rej) => actions.fetchOne('valuations', r, res, rej))).then(resp => {
            const model = getIn(resp, `${this.props.modelname}.${this.props.modelid}`)
            this.setState({ initvals: this.initModel(null, model) })
          }).catch(err => this.handleSubmitError(err, actions, this.form))
        }
        break
      }
      case 'Decline': {
        if (this.props.model) {
          new Promise((resolve, reject) => actions.updateModel({
            noloader: true,
            values: {
              modelname,
              id: this.props.model.id,
              lightstone_id: this.props.model.lightstone_id,
              lightstone_data: this.form.values.lightstone_data,
              status: 'Declined'
            },
            resolve,
            reject
          })).then(r => new Promise((res, rej) => actions.fetchOne('valuations', r, res, rej))).then(resp => {
            const model = getIn(resp, `${this.props.modelname}.${this.props.modelid}`)
            this.setState({ initvals: this.initModel(null, model) })
          }).catch(err => this.handleSubmitError(err, actions, this.form))
        }
        break
      }
      default:
        if (btn.label.includes('Save')) {
          this.form.validateForm().then(a => {
            // eslint-disable-next-line no-unused-vars
            const { section, model: modelvalue, template, ...valid } = this.form.values
            if (Object.keys(a).length === 0 && this.form.values && this.form.values.id) { // Edit
              let values = {
                modelname,
                id: this.form.values.id,
                quiet: true
              }
              if (match.params.model === 'marketing-emails') { values.quiet = false }
              let page
              if ([ 'seller-feedback', 'landlord-feedback' ].includes(getIn(match, 'params.action'))) {
                page = 'seller-feedback'
              } else if (getIn(match, 'params.model') === 'valuations') {
                page = 'standard-valuation'
              }
              if (page === 'seller-feedback') {
                values.seller_feedback_config = valid.seller_feedback_config
              } else {
                if (page === 'standard-valuation' && !valid.lightstone_id) { return }
                values = { ...values, ...this.form.values }
              }
              new Promise((resolve, reject) => actions.updateModel({
                noloader: true,
                values,
                resolve,
                reject
              }))
                .then(r => new Promise((res, rej) => actions.fetchOne(modelname, r, res, rej)))
                .catch(err => this.handleSubmitError(err, actions, this.form))
            } else if (Object.keys(a).length === 0 && this.form.values && !this.form.values.id) { // Create / add
              let values = {
                modelname,
                quiet: true
              }
              let page
              if ([ 'seller-feedback', 'landlord-feedback' ].includes(getIn(match, 'params.action'))) {
                page = 'seller-feedback'
              }
              if (page === 'seller-feedback') {
                values.seller_feedback_config = valid.seller_feedback_config
              } else {
                values = { ...values, ...this.form.values }
              }
              new Promise((resolve, reject) => actions.createModel({
                values,
                resolve,
                reject
              })).then(r => {
                if (this.props.config.modelactions.add?.redirect) {
                  const redirect = parseURL(this.props.config.modelactions.add.redirect, {
                    ...r,
                    site: this.props.user.agent.site.id
                  })
                  return actions.registerRedirect(redirect)
                } else if (this.props.config.modelactions.save.redirect[this.props.match.params.action]) {
                  const url = this.props.config.modelactions.save.redirect[this.props.match.params.action]
                  const redirect = parseURL(url, {
                    ...r,
                    ...this.props.match.params
                  })
                  return actions.registerRedirect(redirect)
                }
                return null
              }).catch(err => this.handleSubmitError(err, this.form, this.form))
            } else {
              const raw = {}
              Object.keys(a).forEach(k => {
                raw[k] = [ a[k] ]
              })
              this.handleSubmitError({ raw }, this.form, this.form)
            }
          }).catch(err => this.handleSubmitError(err, this.form, this.form))
        } else {
          actions[btn.action]()
        }
    }
  }

  render() {
    const { config, actions, ui, model, modelname, user, configs, match, cache, routeConfig, modelid } = this.props
    const hasTabs = !!config.tabs
    if (this.props.modelid && Object.values(this.state.initvals).length < 2) {
      return null
    }
    let width = '100%'
    if (this.state.view === 'Mobi') {
      width = 320
    } else if (getIn(this.props, 'match.params.model') === 'pages') {
      width = this.state.width
    }
    return (
      <Formik
        validationSchema={[ 'seller-feedback', 'landlord-feedback' ].includes(getIn(this.props, 'match.params.action')) ? (
          null
        ) : (
          getIn(
            validate, `${user.agent.site.region}.${modelname}`,
            getIn(validate, `default.${modelname}`)
          )
        )}
        validateOnChange
        initialValues={this.state.initvals}
        enableReinitialize={true}
        onSubmit={(values, form) => {
          // eslint-disable-next-line no-unused-vars
          const { section, model: modelvalue, template, ...valid_values } = values
          const { preview: lastPreview } = this.state
          if (!this.state.loading) {
            form.setSubmitting(true)
            this.setState({ loading: true, preview: '' }, () => {
              new Promise((resolve, reject) => {
                let valid = {
                  modelname,
                  id: model ? model.id : null,
                  quiet: true
                }
                let page
                if ([ 'seller-feedback', 'landlord-feedback' ].includes(getIn(match, 'params.action'))) {
                  page = 'seller-feedback'
                }
                if (page === 'seller-feedback') {
                  valid.seller_feedback_config = valid_values.seller_feedback_config
                } else {
                  valid = {
                    ...valid,
                    ...valid_values,
                    template
                  }
                }
                if (modelvalue === 'pages' && !model) {
                  const domain = getIn(cache, `domains.${valid.domain}`)
                  if (domain.meta) {
                    delete domain.meta
                  }
                  valid.meta = { domain }
                  valid.slug = slugify(valid.title)
                }
                let endpoint = ([ 'seller-feedback', 'landlord-feedback' ].includes(getIn(this.props, 'match.params.action')) && model) ? `/reports/api/v1/${template}/${model.site}/${model.model}/${model.uuid}/` : `${parseURL(config.endpoint.preview, model ? model : valid, null, this.props.cache.settings[this.props.user.agent.site.id])}/`
                if (model && model.path) {
                  endpoint = endpoint.replace(`/${model.slug}/`, model.path)
                }
                const params = {
                  ...valid,
                  endpoint: {
                    write: endpoint
                  },
                  resolve,
                  reject
                }
                return actions.updateTemplatePreview(params)
              }).then(preview => {
                this.setState({ preview })
              }).catch(e => {
                if (Object.keys(e).includes('id__in') || Object.keys(e).includes('template')) {
                  form.setErrors(e.response)
                }
                form.setSubmitting(false)
                this.setState({ loading: false, preview: lastPreview })
                log.error(e)
              })
            })
          }
        }}
      >{formik => {
          this.form = formik
          if (hasTabs && getIn(match, 'params.tab') === 'activity') {
            const tabs = {}
            Object.keys(config.fieldgroups).forEach(g => {
              if (!tabs[config.fieldgroups[g].tab]) {
                tabs[config.fieldgroups[g].tab] = []
              }
              tabs[config.fieldgroups[g].tab].push({ ...config.fieldgroups[g], name: g })
            })
            return (
              <div id="content" className="content">
                <div className="viewhead details">
                  <div className="action-bar">
                    <ModelActions
                      touched={this.form.touched}
                      errors={this.form.errors}
                      isSubmitting={this.form.isSubmitting}
                      handleSubmit={this.modelActionsSubmit}
                      redirectSchema={this.redirectSchema}
                      form={this.form}
                      modelname={getIn(configs, `${modelname}.modelname`)}
                      extras={this.getButtons()}
                      statusmsg={this.form.status ? this.form.status.msg : false}
                    />
                  </div>
                </div>
                <div className="view details">
                  <div className="viewcontent">
                    <HorizontalTabs
                      config={routeConfig}
                      location={location}
                      match={match}
                      model={model}
                      modelid={modelid}
                      defaultTab={slugify(config.tabs[0])}
                      user={{ permissions: user.permissions, agent: user.agent }}
                    >
                      {config.tabs.map((tab, tabidx) => (
                        <Tab key={`tab-${tabidx}`} tab={slugify(tab)} noScroll label={tab}>
                          {tab === 'Activity' && this.state.template_config ? (
                            <Activity
                              model={model}
                              cache={cache}
                              config={config}
                              templateconfig={this.state.template_config}
                              events={model.activity}
                              user={user}
                              settings={{ currency: cache.settings[user.agent.site.id].default_currency }}
                              agents={cache && cache.agents ? cache.agents : {}}
                              fetchActivity={this.fetchActivity}
                              fetchOne={actions.fetchOne}
                              fetchMany={actions.fetchMany}
                            />
                          ) : null}
                        </Tab>
                      ))}
                    </HorizontalTabs>
                  </div>
                </div>
              </div>
            )
          }
          return (
            <div id="content" className="content">
              <div className="viewhead details">
                <div className="action-bar">
                  <ModelActions
                    touched={formik.touched}
                    errors={formik.errors}
                    isSubmitting={formik.isSubmitting}
                    handleSubmit={this.modelActionsSubmit}
                    redirectSchema={this.redirectSchema}
                    form={formik}
                    modelname={getIn(configs, `${modelname}.modelname`)}
                    extras={this.getButtons()}
                    statusmsg={formik.status ? formik.status.msg : false}
                  />
                </div>
              </div>
              <div className={`view details html-preview brochures ${config.modelname}`}>
                <div className="viewcontent">
                  <div className="brochure-generator">
                    <div className='html-preview-form brochure-form'>
                      <CustomForm
                        key="preview-form"
                        ui={ui}
                        onChange={(changes, form) => {
                          if (changes.filter(f => f.includes('override')).length && Object.keys(form.touched).length > 0) { // Only fire when touched
                            changes.forEach(changedfield => {
                              if (
                                Object.keys(form.touched).includes(changedfield) &&
                                    getIn(form.values, changedfield)
                              ) {
                                config.fields.filter(f => f.name === changedfield && f.onchange).forEach(cb => {
                                  actions[cb.onchange](cb, form) // Perform the required action
                                })
                              }
                            })
                          }
                          if (changes.includes('section')) {
                            this.setState({ section: form.values.section })
                          }
                          if (changes.includes('template')) {
                            const newInitvals = this.initModel(null, form.values)
                            if (!isEqual(this.state.initvals, newInitvals)) {
                              this.setState({ initvals: newInitvals }, () => {
                                this.fetchTemplateConfigs(this.state.initvals.template)
                              })
                            } else if (modelname === 'pages') {
                              this.fetchTemplateConfigs(form.values.template)
                            }
                          }
                        }}
                        model={this.props.model ? true : false}
                        setContextForm={this.props.actions.setContextForm}
                        render={() => (hasTabs ? this.renderTabs(formik) : this.renderGroups(formik))}
                      />
                    </div>
                    <div className='preview-pane brochure-preview'>
                      <Card
                        background={true}
                        header={
                          <h3>Preview</h3>
                        }
                        bodyclass="no-padding"
                        body={
                          <div className='previewcontainer'>
                            <nav id='previewcontainer-nav' className='previewcontainer-nav'>
                              {this.state.showActions ? (
                                <div className="nav-group">
                                  <Button icon="#icon24-Website" title="Desktop" className="btn btn-subtle btn-icon-16 btn-icon-only" type='button' onClick={() => this.setState({ view: 'Desktop' })} />
                                  <Button icon="#icon24-Mobile" title="Mobi" className="btn btn-subtle btn-icon-16 btn-icon-only" type='button' onClick={() => this.setState({ view: 'Mobi' }, () => {
                                    this.checkSize()
                                  })} />
                                </div>
                              ) : null }
                            </nav>
                            <div className='previewcontainer-content' ref={el => { if (el) { this.el = el } }} style={{
                              width: this.state.view === 'Mobi' ? 320 : '100%',
                              height: getIn(this.props, 'match.params.model') === 'pages' && this.state.view !== 'Mobi' ? this.state.height * this.state.scale : 'auto',
                              overflow: getIn(this.props, 'match.params.model') === 'pages' && this.state.view !== 'Mobi' ? 'hidden' : 'auto'
                            }}>
                              {this.state.loading ? <Loader onError={() => {}} throwViewError={() => {}} /> : null}
                              <iframe
                                key={`iframe-${this.props.config.model}`}
                                ref={el => (this.preview = el)}
                                scrolling={getIn(this.props, 'match.params.model') === 'pages' ? 'yes' : 'no'}
                                onLoad={() => {
                                  const obj = this.preview
                                  if (getIn(this.props, 'match.params.model') === 'pages') {
                                    const viewWidth = this.state.view === 'Mobi' ? 320 : 1440
                                    const scale = this.state.view !== 'Mobi' ? this.el.clientWidth / viewWidth : 1
                                    this.setState({
                                      loading: false,
                                      width: viewWidth,
                                      height: this.state.view === 'Mobi' ? 800 : 920,
                                      scale: scale
                                    }, () => {
                                      formik.setSubmitting(false)
                                    })
                                  } else {
                                    this.setState({
                                      loading: false,
                                      width: obj.contentWindow.document.body.scrollWidth,
                                      height: Math.max(
                                        document.body.scrollHeight,
                                        obj.contentWindow.document.body.scrollHeight + 15
                                      )
                                    }, () => {
                                      formik.setSubmitting(false)
                                    })
                                  }
                                }}
                                style={{
                                  height: this.state.height,
                                  width,
                                  transformOrigin: 'top left',
                                  transform: this.state.view === 'Mobi' ? 'scale(1.0)' : `scale(${this.state.scale})`
                                }}
                                srcDoc={this.state.preview}
                              />
                            </div>
                          </div>
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        } }
      </Formik>
    )
  }
}

HTMLPreview.propTypes = {
  model: PropTypes.object,
  location: PropTypes.object,
  fetchOne: PropTypes.func,
  modelname: PropTypes.string,
  modelid: PropTypes.string,
  routeConfig: PropTypes.object,
  actions: PropTypes.object,
  cache: PropTypes.object,
  sourcemodel: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool.apply
  ]),
  configs: PropTypes.object,
  config: PropTypes.object,
  match: PropTypes.object,
  ui: PropTypes.object,
  user: PropTypes.object,
  addons: PropTypes.array,
  templates: PropTypes.array,
  template_id: PropTypes.number,
  registerRedirect: PropTypes.func
}

export default HTMLPreview
