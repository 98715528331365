import React, { useRef } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import isEqual from 'react-fast-compare'
import classNames from 'classnames'

import Tooltip from './Tooltip'


class TooltipTracker extends React.Component {
  constructor(props) {
    super(props)
    this.over = false
    this.hover = null
    this.box = {}
    this.state = {
      visible: props.visible,
      screenOffset: 0,
      hoverComponentStyle: {
        display: 'block',
        visibility: 'hidden',
        position: 'absolute'
      }
    }
    this.handleMouseOver = this.handleMouseOver.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
    this.handleMouseMove = this.handleMouseMove.bind(this)
    this.setVisibility = this.setVisibility.bind(this)
    this.setPosition = this.setPosition.bind(this)
  }

  componentDidMount() {
    this.el = document.getElementById('wrapper')
    if (this.props.visible) {
      this.setVisibility({ currentTarget: this.container })
      this.setState({ visible: this.props.visible }, () => {
        this.over = this.state.visible
        this.setVisibility({ currentTarget: this.container })
      })
    }
  }

  componentDidUpdate(prevProps) {
    this.setVisibility({ currentTarget: this.container })
    if (prevProps.visible !== this.props.visible) {
      this.setState({ visible: this.props.visible }, () => {
        this.over = this.state.visible
      })
    }
  }

  componentWillUnmount() {
    if (this.style) {
      this.style.parentNode.removeChild(this.style)
      this.style = null
    }
  }

  handleMouseOver(e) {
    this.over = true
    const el = e.currentTarget
    this.setState({ visible: true }, () => { this.setVisibility({ currentTarget: el }) })
  }

  handleMouseLeave(e) {
    this.over = false
    this.setVisibility(e)
    if (this.style) {
      this.style.parentNode.removeChild(this.style)
      this.style = null
    }
  }

  handleMouseMove(e) {
    this.over = true
    this.setVisibility(e)
  }

  setPosition(e) {
    const { options } = this.props
    const { followCursor } = options
    let { shiftX, shiftY } = options
    if (!followCursor) { return }
    const { top, right, bottom, left } = this.box
    const cursorX = e.clientX
    const cursorY = e.clientY
    const { hoverComponentStyle } = this.state
    let updatedStyles = { ...hoverComponentStyle }
    if (isNaN(shiftX)) {
      shiftX = 0
    }
    if (isNaN(shiftY)) {
      shiftY = 0
    }
    if (!this.over) {
      return
    }
    if (cursorX > left && cursorX < right && cursorY > top && cursorY < bottom) {
      if (followCursor) {
        updatedStyles = { ...updatedStyles, top: cursorY + shiftY, left: cursorX + shiftX, position: 'fixed' }
      }
    } else {
      updatedStyles = { ...hoverComponentStyle, display: 'block', visibility: 'hidden' }
    }
    clearTimeout(this.hover)
    if (!isEqual(hoverComponentStyle, updatedStyles)) {
      this.hover = setTimeout(this.setState({
        hoverComponentStyle: updatedStyles
      }))
    }
  }


  setVisibility(e) {
    const box = e.currentTarget.getBoundingClientRect()
    let tooltip
    const cursorX = e.clientX
    const cursorY = e.clientY
    const { hoverComponentStyle } = this.state
    let updatedStyles = null
    const { options } = this.props
    const { followCursor, capWidth } = options
    let { shiftX, shiftY } = options
    let diff = 0
    updatedStyles = { ...hoverComponentStyle }
    this.tooltip = this.el.querySelector('.tc-tooltip')
    if (this.tooltip) {
      tooltip = this.tooltip.getBoundingClientRect()
    } else {
      tooltip = {}
    }
    if (document.body.style) {
      // fix top position when body has margin top
      shiftY -= parseInt(document.body.style.marginTop, 10)
    }

    if (isNaN(shiftX)) {
      shiftX = 0
    }
    if (isNaN(shiftY)) {
      shiftY = 0
    }

    if (followCursor) {
      updatedStyles = { ...updatedStyles, top: cursorY + shiftY, left: cursorX + shiftX, position: 'fixed' }
    } else if (this.tooltip) {
      if ([ 'bottom', 'bottom-left' ].includes(options.arrow)) {
        updatedStyles = { ...updatedStyles, top: box.top + window.scrollY - tooltip.height + shiftY, left: box.left + (box.width / 2) - (tooltip.width / 2) + shiftX, position: 'absolute', display: 'block' }
      } else {
        updatedStyles = { ...updatedStyles, top: box.top + window.scrollY + box.height + shiftY, left: box.left + (box.width / 2) - (tooltip.width / 2) + shiftX, position: 'absolute', display: 'block' }
      }
    }
    if (this.props.rootRef.current && this.props.rootRef.current.getAttribute('id') !== 'wrapper') {
      const elBox = this.props.containerRef.getBoundingClientRect()
      updatedStyles.top = `calc(${this.props.containerRef.style.top} + ${elBox.height}px)`
      updatedStyles.left = this.props.containerRef.style.left
    }
    if ([ 'bottom-left' ].includes(options.arrow) && this.tooltip) {
      updatedStyles.left += (tooltip.width / 2)
    }
    if (updatedStyles.left + tooltip.width >= document.body.clientWidth) {
      diff = -((updatedStyles.left + tooltip.width) + 10 - document.body.clientWidth)
    } else if (updatedStyles.left < 0) {
      diff = 20
    }
    if (capWidth) {
      if (!isNaN(capWidth)) {
        updatedStyles.maxWidth = box.width
      } else {
        updatedStyles.maxWidth = capWidth
      }
    }
    if (!isNaN(diff) && !isNaN(updatedStyles.left)) {
      updatedStyles.left += diff
    }
    if (this.tooltip) {
      const left = box.left - updatedStyles.left + (box.width / 2)
      this.rules = [
        `
        .tc-tooltip-arrow-${options.arrow}:before {
          left: ${left}px;
        }`,
        `
        .tc-tooltip-arrow-${options.arrow}:after {
          left: ${left}px;
        }`
      ]
      if (diff) {
        if (!this.style) {
          this.style = document.createElement('style')
          document.head.appendChild(this.style)
          this.rules.map((r, idx) => this.style.sheet.insertRule(r, idx))
          updatedStyles = { ...updatedStyles, display: 'block', visibility: 'visible' }
        }
      } else {
        updatedStyles = { ...updatedStyles, display: 'block', visibility: 'visible' }
        if (this.style && this.style.sheet) {
          this.rules.map((r, idx) => this.style.sheet.insertRule(r, idx))
        }
      }
    }
    if (!isEqual(hoverComponentStyle, updatedStyles) || this.over !== this.state.visible) {
      this.setState({
        hoverComponentStyle: updatedStyles,
        visible: this.over
      })
    }
  }

  render() {
    const { children, className, containerRef } = this.props
    return (
      <div ref={el => { this.container = containerRef ? containerRef : el }} className={`${className} tc-tooltip-wrapper`} onMouseOver={this.props.visible === undefined ? this.handleMouseOver.bind(this) : null} onMouseMove={this.props.visible === undefined ? this.handleMouseMove.bind(this) : null} onMouseLeave={this.props.visible === undefined ? this.handleMouseLeave.bind(this) : null}>
        {children}
        {this.props.render(this.state)}
      </div>
    )
  }
}

TooltipTracker.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  options: PropTypes.object,
  render: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  content: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  visible: PropTypes.bool,
  clickable: PropTypes.bool,
  containerRef: PropTypes.object,
  rootRef: PropTypes.object
}

const TooltipContext = props => {
  const { title, content, options, clickable, rootRef } = props
  const root = useRef(rootRef ? rootRef : document.getElementById('wrapper'))
  const tooltip = useRef()
  return (
    <TooltipTracker {...props}
      rootRef={root}
      render={state => (state.visible ? ReactDOM.createPortal(<Tooltip
        ref={el => {tooltip.current = (el && el.tooltip) ? el.tooltip.current : null}}
        clickable={clickable}
        className={classNames(options.arrow ? ` tc-tooltip-arrow-${options.arrow}` : '', { 'tc-tooltip-clickable': clickable })}
        style={state.hoverComponentStyle}
        title={title}
        content={content}
      />, root.current) : null)}
    />
  )
}

TooltipContext.propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  options: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  content: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  clickable: PropTypes.bool,
  rootRef: PropTypes.object
}

export default TooltipContext
