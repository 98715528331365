import classNames from 'classnames'
import { ErrorMessage } from 'formik'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import NumberFormat from 'react-number-format'
import { getIn } from 'formik'

import HelpText from './../../HelpText'
import Label from './Label'


const FloatInput = props => {
  const { value, name } = props.field
  const { defaultValue } = props
  const [ val, setVal ] = useState(value || null)
  const [ isFocused, setIsFocused ] = useState(false)
  const [ needsUpdate, setNeedsUpdate ] = useState(false)

  useEffect(() => {
    setNeedsUpdate(true)
  }, [ isFocused ])

  useEffect(() => {
    if (value && !val || (val && value && (!isFocused || needsUpdate))) {
      setVal(value)
      if (needsUpdate) {
        setNeedsUpdate(false)
      }
    } else if (val && !value) {
      setVal(value)
    }
  }, [ value, isFocused, needsUpdate ])

  useEffect(() => {
    if (!defaultValue && ![ undefined ].includes(props._value) && !val) {
      setVal(![ undefined, null ].includes(props._value) ? props._value : '')
    } else if (
      !isFocused &&
      ![ undefined, null ].includes(props._value) &&
      parseFloat(val) !== parseFloat(props._value)
    ) {
      setVal(![ undefined, null ].includes(props._value) ? props._value : '')
    }
  }, [ props._value, isFocused ])

  useEffect(() => {
    if ([ undefined, '' ].includes(val) || isNaN(val)) { // is empty, undefined or not a number (null is allowed)
      props.form.setFieldValue(name, null, false)
    } else if (val !== value && parseFloat(val) !== parseFloat(value) && (!isNaN(val) || val === null)) { // has changed, is a valid number or null
      props.form.setFieldValue(name, val ? parseFloat(val) : null, false)
    }
  }, [ val ])

  const { label, className, classes, disabled, id, suffix, actions, field, form, step } = props

  return (
    <div id={id} className={`form-group ${name} ${classes}`}>
      {label &&
      <Label htmlFor={name}>
        {label}
      </Label>
      }
      <div className={classNames('forminput', 'float', className)}>
        <NumberFormat
          className={classNames('form-control  form-control-lg', className)}
          decimalScale={step !== 1 ? 2 : 0}
          thousandSeparator={' '}
          value={val}
          name={`${name}formatted`}
          placeholder={props.placeholder}
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            setIsFocused(false)
            form.setFieldTouched(name, true).then(() => {
              if (props.onBlur && actions && getIn(actions, props.onBlur)) {
                actions[props.onBlur]({ field: field, form: form })
              }
            })
          }}
          id={`${name}-formatted`}
          onValueChange={values => {
            if (!values.value) { values.value = null }
            if (parseFloat(val) !== parseFloat(values.value) && !isNaN(values.value)) {
              setVal(values.value)
            }
          } }
          allowNegative={false}
          isNumericString
          allowEmptyFormatting={false}
          type='tel'
          disabled={disabled}
        />

        <input
          id={name}
          name={name}
          type="hidden"
          value={[ null, undefined, '' ].includes(value) ? '' : value}
        />
        {suffix}
        {props.help &&
          <span className="help-text">
            {props.help.map(component => (<HelpText {...component} key={`input-${name}-help`} />))}
          </span>
        }
        <ErrorMessage name={name} render={msg => <div className="error">{msg.replace(name, label)}</div> } />
      </div>
    </div>
  )
}

FloatInput.propTypes = {
  id: PropTypes.string,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  defaultValue: PropTypes.number,
  _value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  placeholder: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  step: PropTypes.number,
  actions: PropTypes.object,
  help: PropTypes.array,
  onBlur: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string
  ]),
  _areas: PropTypes.bool,
  _suburbs: PropTypes.bool,
  protected: PropTypes.bool,
  required: PropTypes.bool,
  classes: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]).isRequired,
  suffix: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default FloatInput
