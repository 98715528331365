import { getIn } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'

import log from '../../logging'
import { getRandomColor, hasPermission, isConditional, valueFormat } from '../../utils'
import Activity from '../common/Activity'
import AgentCard from '../common/AgentCard'
import Card from '../common/Card'
import DetailsList from '../common/DetailsList'
import MediaGallery from '../common/MediaGallery'
import MetaDetail from '../common/MetaDetail'
import Syndication from '../common/Syndication'
import Notes from '../common/notes/Notes'
import HorizontalTabs from '../common/tabs/HorizontalTabs'
import Tab from '../common/tabs/Tab'
import { default as SocialMedia } from '../ui/SocialMedia'
import BarGraph from '../ui/graphs/BarGraph'
import PieChart from '../ui/graphs/PieChart'
import ActiveListingsWidget from '../widgets/ActiveListingsWidget'
import SocialPerformanceWidget from '../widgets/SocialPerformanceWidget'
import AgentAvailability from '../common/AgentAvailability'
import AgentCalendar from '../common/AgentCalendar'


class Agent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pie_chart: null
    }
    this.isConditional = isConditional.bind(this)
    this.getSourcesStat = this.getSourcesStat.bind(this)
  }

  componentDidMount() {
    new Promise((resolve, reject) => {
      this.props.actions.fetchLeadsBreakdown(
        {
          modelname: this.props.config.modelname,
          params: {
            active__in: '1',
            agent__in: `${this.props.model.id}`
          },
          resolve,
          reject
        }
      )
    }).then(r => {
      this.getSourcesStat(r.leads.source_counts, 'lead_sources')
    }).catch(() => {})
  }

  sendActivation() {
    new Promise((resolve, reject) => {
      const values = {
        agent_id: this.props.model.id,
        email: this.props.model.meta.pending_user.email
      }
      return this.props.actions.sendActivation({ values, resolve, reject })
    }).then(() => {
      // We need to indicate success here
    }).catch(e => {
      log.error(e)
    })
  }

  async getSourcesStat(data, state_key) {
    if (this[`${state_key}_fetching`] || !data || Object.keys(data).legth === 0) {
      return null
    }
    const array_data = Object.keys(data)
      .map(k => ({ source: k, value: data[k] }))
      .sort((a, b) => (b.value - a.value))
    this[`${state_key}_fetching`] = true
    const total = array_data.reduce((prevValue, nextValue) => prevValue + nextValue.value, 0)
    const top_9 = array_data.slice(0, 9)

    let top_9_total = 0
    top_9.forEach(area => {
      const area_id = Object.keys(area).pop()
      top_9_total += area[area_id]
    })
    const slices = []
    top_9.forEach(source => {
      const source_key = source.source
      const count = source.value
      if (count) {
        slices.push({
          name: `${source_key} (${Math.round(count / total * 100)}%)`,
          value: count,
          percentage: count / top_9_total,
          colour: getRandomColor(slices.length)
        })
      }
    })
    const other_totals = total - top_9_total
    if (other_totals) {
      slices.unshift({
        name: `Other (${Math.round(other_totals / total * 100)}%)`,
        value: other_totals,
        percentage: 0,
        percentage_100: 0,
        colour: getRandomColor(slices.length)
      })
    }
    this.setState({ pie_chart: slices })
    return slices
  }

  render() {
    const {
      model,
      user,
      cache,
      match,
      config,
      actions,
      routeConfig
    } = this.props

    const listingstats = [
      [ 'rfs_ac', 'RFS' ],
      [ 'rtl_ac', 'RTL' ],
      [ 'cfs_ac', 'CFS' ],
      [ 'ctl_ac', 'CTL' ],
      [ 'rd', 'RD' ],
      [ 'cd', 'CD' ],
      [ 're', 'RE' ],
      [ 'ce', 'CE' ],
      [ 'h_ac', 'HL' ]
    ]

    const { statistics } = model.meta
    const selected_site = user.agent && user.agent.site ? user.agent.site.id : 0
    const sitestats = getIn(cache, `settings.${selected_site}.meta.statistics`, {})

    const listing_stats = []
    listingstats.forEach(stat => {
      const avg = sitestats.active_agents ? Math.round(sitestats[stat[0]] / sitestats.active_agents) : 0
      const stats = {}
      stats.name = stat[1]
      stats['Company Avg'] = avg
      stats[`${model.first_name} ${model.last_name}`] = statistics[stat[0]]
      listing_stats.push(stats)
    })

    return (
      <HorizontalTabs
        config={routeConfig}
        location={this.props.location}
        match={match}
        model={model}
        defaultTab="details"
        user={{ permissions: user.permissions, agent: user.agent }}
        addons={this.props.addons}
      >
        <Tab tab="dashboard" label="Dashboard">
          <div className="grid-container">
            <div className="grid-rows grid-col-4 grid-container">
              <Card
                classes="grid-col-3"
                background
                header={
                  <h3>Contact Details</h3>
                }
                bodyclass="no-top-padding"
                body={() => (
                  <div className="input-group gap-20 space-evenly">
                    <div className="contact-details">
                      <div className="input-group gap-20 space-evenly">
                        {[ 'cell_number', 'office_number' ].map((f, fidx) => {
                          if (f === 'office_number') {
                            const val = getIn(model, 'meta.display_on_branches.0.telephone_number')
                            return (
                              <MetaDetail
                                className="col-lg-6 dashboard-meta"
                                key={`f-1-${fidx}`}
                                label="Office Number"
                                value={val}
                                format={'tel'}
                              />
                            )
                          }
                          const field = config.fields.find(fe => fe.name === f)
                          let val = model[field.name]
                          if (field.modelname || field.metafield) {
                            if (field.optionlabel) {
                              val = getIn(model.meta, `${field.name}.${field.optionlabel}`)
                            }
                          }
                          return (
                            <MetaDetail
                              className="col-lg-6 dashboard-meta"
                              key={`f-1-${fidx}`}
                              label={field.label}
                              value={val}
                              format={field.format}
                              field={field}
                            />
                          )
                        }
                        )}
                      </div>
                      {[ 'email', 'id_number' ].map((f, fidx) => {
                        const field = config.fields.find(fe => fe.name === f)
                        let val = model[field.name]
                        if (field.modelname || field.metafield) {
                          if (field.optionlabel) {
                            val = getIn(model.meta, `${field.name}.${field.optionlabel}`)
                          }
                        }
                        return (
                          <MetaDetail
                            className="dashboard-meta"
                            key={`f-2-${fidx}`}
                            label={field.label}
                            value={val}
                            format={field.format}
                            field={field}
                          />
                        )
                      }
                      )}
                      <div className="branch-tel-number">
                        {model.telephone_number}
                      </div>
                    </div>
                    <div className="address-details">
                      {model.physical_address && <div className="branch-address">
                        <div className="dashboard-meta">
                          <div className="heavy">Physical Address</div>
                          <span>{valueFormat('domstring', model.physical_address)}</span>
                        </div>
                      </div>}
                      {model.postal_address && <div className="branch-postal-address">
                        <div className="dashboard-meta">
                          <div className="heavy">Postal Address</div>
                          <span>{valueFormat('domstring', model.postal_address)}</span>
                        </div>
                      </div>}
                    </div>
                    <SocialMedia model={model} />
                  </div>
                )}
              />
              <Card
                classes="grid-col-1 grid-row-3"
                bodyclass="flex-container justify-center"
                background
                body={<AgentCard actions={actions} bigNumber showLinks agent={model} user={user} />}
              />
              <ActiveListingsWidget agent={model} />
              <Card
                classes="primary-bg grid-col-1 grid-row-2"
                bodyclass="stats-card no-top-padding"
                background
                header={
                  <strong>Leads</strong>
                }
                body={
                  <>
                    <h1>{statistics.active_electronic_leads}</h1>
                    <span>
                      Company Avg: {sitestats.active_agents ? (
                        Math.round(sitestats.active_electronic_leads / sitestats.active_agents)
                      ) : 0}
                    </span>
                  </>
                }
              />
              <Card
                classes="primary-bg grid-col-1 grid-row-2"
                bodyclass="stats-card no-top-padding"
                background
                header={
                  <strong>Profiles</strong>
                }
                body={
                  <>
                    <h1>{statistics.active_profiles}</h1>
                    <span>
                      Company Avg: {sitestats.active_agents ? (
                        Math.round(sitestats.active_profiles / sitestats.active_agents)
                      ) : 0}
                    </span>
                  </>
                }
              />
            </div>
            <Card
              classes="grid-col-3"
              bodyclass="bar-graph"
              background
              header={
                <h3>Listings Breakdown</h3>
              }
              body={
                <BarGraph data={listing_stats} dataKeys={[ `${model.first_name} ${model.last_name}`, 'Company Avg' ]} dataColors={[ getRandomColor(1), getRandomColor(0) ]} />
              }
            />
            <Card
              classes="grid-col-1"
              bodyclass="pie-chart"
              background
              header={
                <h3>Leads Breakdown</h3>
              }
              body={this.state.pie_chart ? (
                <PieChart data={this.state.pie_chart} />
              ) : null
              }
            />
            <SocialPerformanceWidget className="grid-col-4" model={model} />
          </div>
        </Tab>

        <Tab handleUpdate={this.props.actions.handleUpdate} tab="details" label="Details" noScroll>
          <DetailsList {...this.props} />
        </Tab>

        <Tab tab="syndication" label="Syndication">
          <Syndication
            model={model}
            servicename={config.servicename}
            modelname={config.modelname}
            portals={cache.portals}
            settings={cache.settings[user.agent.site.id]}
            permissions={user.permissions}
            agentid={user.agent.id}
            actions={actions}
            user={user}
          />
        </Tab>

        <Tab tab="media" label="Media">
          <MediaGallery
            media={[
              {
                items: [ model.meta.image ],
                mediatype: 'image',
                fieldname: 'image',
                plural: 'photos',
                label: 'Photo'
              },
              {
                items: model.meta.documents,
                mediatype: 'documents',
                fieldname: 'documents',
                plural: 'documents',
                label: 'Documents'
              },
              {
                items: model.meta.private_documents,
                mediatype: 'documents',
                fieldname: 'private_documents',
                plural: 'private documents',
                label: 'Private Documents'
              }
            ]}
            actions={{
              downloadImages: actions.downloadImages,
              isConditional: this.isConditional
            }}
            config={config}
            model={model}
            modelname={config.modelname}
          />
        </Tab>

        <Tab tab="notes" label="Notes / Actions">
          <Notes
            config={config}
            model={model}
            user={user}
            match={match}
            actions={actions}
            cache={cache}
          />
        </Tab>

        {(hasPermission([ 'users_update_scheduling' ], user.permissions) && model.id === user.agent.id) || (hasPermission([ 'users_update_scheduling' ], user.permissions) && hasPermission([ 'users_update' ], user.permissions, model, user.agent.id)) ? (
          <Tab tab="availability" label="Availability">
            <AgentAvailability
              config={config}
              model={model}
              actions={actions}
              cache={cache}
              user={user}
            />
          </Tab>
        ) : null}

        <Tab tab="calendar" label="Calendar">
          <AgentCalendar
            config={config}
            model={model}
            actions={actions}
            cache={cache}
            user={user}
          />
        </Tab>

        <Tab tab="activity" label="Activity">
          <Activity
            model={model}
            cache={cache}
            config={config}
            events={model.activity}
            user={user}
            settings={{ currency: cache.settings[user.agent.site.id].default_currency }}
            agents={cache && cache.agents ? cache.agents : {}}
            fetchActivity={actions.fetchActivity}
            fetchOne={actions.fetchOne}
            fetchMany={actions.fetchMany}
          />
        </Tab>

      </HorizontalTabs>
    )
  }
}

Agent.propTypes = {
  selected: PropTypes.array,
  model: PropTypes.object,
  actions: PropTypes.object,
  config: PropTypes.object,
  cache: PropTypes.object,
  user: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  addons: PropTypes.array,
  routeConfig: PropTypes.object
}

export default Agent
