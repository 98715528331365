/* eslint-disable no-warning-comments */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Formik } from 'formik'
import { getIn } from 'formik'
import isEqual from 'react-fast-compare'
import Card from '../Card'
import CustomForm from '../forms/CustomForm'
import { Button } from '../../ui/Button'
import FieldGroup from '../forms/FieldGroup'
import { valueFormat, hasPermission, breakpoint, handleSubmitError } from '../../../utils'
import Loader from '../Loader'
import Banner from '../banners/Banner'
import LeadDetails from './LeadDetails'
import LeadInteractionsHistory from './LeadInteractionsHistory'
import LeadLog from './LeadLog'


class LeadInteractions extends React.Component {
  constructor(props) {
    super(props)
    this.configs = {
      communication: {
        fields: [
          {
            name: 'communication_type',
            label: 'Communication Type',
            input: 'Select',
            options: [
              { label: 'Email', value: 'Email' },
              { label: 'Phone Call', value: 'Phone Call' },
              { label: 'SMS/WhatsApp', value: 'SMS/WhatsApp' },
              { label: 'In Person', value: 'In Person' }
            ],
            edit: true,
            required: true
          },
          {
            name: 'interested',
            label: 'Interested in Viewing',
            input: 'Select',
            options: [
              { label: 'Yes', value: 'Yes' },
              { label: 'Maybe', value: 'Maybe' },
              { label: 'No', value: 'No' }
            ],
            cols: 'lg-12',
            edit: true,
            required: true
          },
          {
            name: 'viewing_date',
            label: 'Scheduled Viewing Date',
            input: 'Date',
            futureonly: true,
            format: 'date',
            cols: 'lg-4',
            edit: [
              [
                {
                  field: 'interested',
                  condition: {
                    value: 'Yes'
                  }
                }
              ]
            ],
            required: [
              [
                {
                  field: 'interested',
                  condition: {
                    value: 'Yes'
                  }
                }
              ]
            ]
          },
          {
            name: 'viewing_start_time',
            label: 'Viewing Start Time',
            input: 'Date',
            format: 'time',
            cols: 'lg-4',
            edit: [
              [
                {
                  field: 'interested',
                  condition: {
                    value: 'Yes'
                  }
                }
              ]
            ],
            required: [
              [
                {
                  field: 'interested',
                  condition: {
                    value: 'Yes'
                  }
                }
              ]
            ]
          },
          {
            name: 'viewing_end_time',
            label: 'Viewing End Time',
            input: 'Date',
            format: 'time',
            cols: 'lg-4',
            edit: [
              [
                {
                  field: 'interested',
                  condition: {
                    value: 'Yes'
                  }
                }
              ]
            ],
            required: [
              [
                {
                  field: 'interested',
                  condition: {
                    value: 'Yes'
                  }
                }
              ]
            ]
          },
          {
            name: 'viewing_type',
            label: 'Viewing Type',
            input: 'Select',
            futureonly: true,
            format: 'choice',
            options: [
              { value: 'In Person', label: 'In Person' },
              { value: 'Video Call', label: 'Video Call' }
            ],
            cols: 'lg-12',
            edit: [
              [
                {
                  field: 'interested',
                  condition: {
                    value: 'Yes'
                  }
                }
              ]
            ],
            required: [
              [
                {
                  field: 'interested',
                  condition: {
                    value: 'Yes'
                  }
                }
              ]
            ]
          },
          {
            name: 'feedback',
            label: 'Note',
            edit: true,
            input: 'TextArea'
          },
          {
            label: 'Tags',
            name: 'tags',
            plural: 'tags',
            singular: 'interaction',
            edit: true,
            bulkedit: true,
            multi: true,
            required: false,
            orderkey: 'label',
            extraparams: 'order_by=label',
            modelname: 'tags',
            optionlabel: 'label',
            input: 'AsyncCreateSelect',
            orderable: true,
            labelformat: {
              head: [ 'label' ],
              tags: [ 'level' ]
            },
            additional_data: {
              level: 'User'
            }
          },
          {
            name: 'archive_lead',
            label: 'Archive Lead',
            edit: [
              [
                {
                  field: 'interested',
                  condition: {
                    value: 'No'
                  }
                }
              ]
            ],
            input: 'Check',
            cols: 'lg-2'
          }
        ]
      },
      viewing: {
        fields: [
          {
            name: 'interested',
            label: 'Interested',
            input: 'Select',
            options: [
              { label: 'Yes', value: 'Yes' },
              { label: 'Maybe', value: 'Maybe' },
              { label: 'No', value: 'No' }
            ],
            edit: true,
            required: true
          },
          {
            name: 'viewing_concerns',
            label: 'Concerns',
            edit: true,
            input: 'CheckGroup',
            usenames: true,
            usevalues: false,
            unique: false,
            options: [
              {
                value: 'Price',
                label: 'Price',
                name: 'feedback_price'
              },
              {
                value: 'Location',
                label: 'Location',
                name: 'feedback_location'
              },
              {
                value: 'Size',
                label: 'Size',
                name: 'feedback_size'
              },
              {
                value: 'Layout',
                label: 'Layout',
                name: 'feedback_layout'
              },
              {
                value: 'Other',
                label: 'Other',
                name: 'feedback_other_check'
              }
            ]
          },
          {
            name: 'feedback_other',
            label: 'Other',
            edit: [
              [
                {
                  field: 'feedback_other_check',
                  condition: {
                    value: true
                  }
                }
              ]
            ],
            required: [
              [
                {
                  field: 'feedback_other_check',
                  condition: {
                    value: true
                  }
                }
              ]
            ],
            input: 'TextArea'
          },
          {
            name: 'viewing_date',
            label: 'Viewing Date',
            input: 'Date',
            format: 'date',
            edit: true,
            required: true
          },
          {
            name: 'feedback',
            label: 'Note',
            edit: true,
            input: 'TextArea'
          },
          {
            label: 'Tags',
            name: 'tags',
            plural: 'tags',
            singular: 'interaction',
            edit: true,
            bulkedit: true,
            multi: true,
            required: false,
            orderkey: 'label',
            extraparams: 'order_by=label',
            modelname: 'tags',
            optionlabel: 'label',
            input: 'AsyncCreateSelect',
            orderable: true,
            labelformat: {
              head: [ 'label' ],
              tags: [ 'level' ]
            },
            additional_data: {
              level: 'User'
            }
          },
          {
            name: 'archive_lead',
            label: 'Archive Lead',
            edit: [
              [
                {
                  field: 'interested',
                  condition: {
                    value: 'No'
                  }
                }
              ]
            ],
            input: 'Check',
            cols: 'lg-2'
          }
        ]
      },
      offer: {
        fields: [
          {
            name: 'offer_amount',
            label: 'Offer',
            input: 'Currency',
            edit: true,
            required: true
          },
          {
            name: 'offer_expiry_date',
            label: 'Offer Expiry Date',
            input: 'Date',
            format: 'date',
            edit: true,
            required: true
          },
          {
            name: 'status',
            label: 'Offer Accepted',
            input: 'Select',
            options: [
              { label: 'Pending', value: 'Pending' },
              { label: 'Accepted', value: 'Accepted' },
              { label: 'Not Accepted', value: 'Not Accepted' }
            ],
            edit: true,
            required: true
          }
        ]
      },
      rental_application: {
        fields: [
          {
            name: 'deposit',
            label: 'Deposit',
            input: 'Currency',
            cols: 'lg-6',
            edit: true
          },
          {
            name: 'key_deposit',
            label: 'Key Deposit',
            cols: 'lg-6',
            input: 'Currency',
            edit: true
          },
          {
            name: 'utility_deposit',
            label: 'Utility Deposit',
            cols: 'lg-6',
            input: 'Currency',
            edit: true
          },
          {
            name: 'lease_fee',
            label: 'Lease Fee',
            input: 'Currency',
            cols: 'lg-6',
            edit: true
          },
          {
            name: 'pro_rata_rent',
            label: 'Pro-rata Rent',
            cols: 'lg-6',
            input: 'Currency',
            edit: true
          },
          {
            name: 'first_months_rent',
            label: 'First Month\'s Rent',
            cols: 'lg-6',
            input: 'Currency',
            edit: true,
            required: true
          },
          {
            name: 'expiry_date',
            label: 'Expiry Date',
            cols: 'lg-6',
            input: 'Date',
            edit: true,
            required: true
          },
          {
            name: 'status',
            label: 'Expiry Date',
            defaultvalue: 'Sent',
            input: 'Hidden'
          }
        ]
      },
      archive: {
        fields: [
          {
            name: 'reason',
            label: 'Reason',
            edit: true,
            input: 'TextArea'
          }
        ]
      }
    }
    this.state = {
      interaction: null,
      interactions: [],
      showActions: breakpoint.matches,
      config: {},
      refresh: false,
      canEdit: this.hasEditPermission()
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.toggleActions = this.toggleActions.bind(this)
    this.hasEditPermission = this.hasEditPermission.bind(this)
  }

  componentDidMount() {
    const { actions, config, model, lead } = this.props
    breakpoint.addEventListener('change', this.toggleActions)
    if (lead) {
      new Promise((resolve, reject) => actions.fetchViewingFeedback({
        modelname: config.modelname,
        action: 'interactions',
        modelid: model.id,
        params: {
          lead: lead.id,
          get_all: 1,
          order_by: '-created'
        },
        resolve,
        reject
      })).then(r => {
        this.setState({ interactions: r })
      }).catch(e => {
        if (e.status !== 408) {
          console.error(e)
        }
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { actions, config, model, lead } = this.props
    if (prevState.interaction !== this.state.interaction) {
      this.setState({ config: getIn(this.configs, this.state.interaction, {}) })
    }
    if (!isEqual(prevProps.lead, this.props.lead)) {
      const canEdit = this.hasEditPermission()
      if (canEdit !== this.state.canEdit) {
        this.setState({ canEdit })
      }
      if (lead) {
        this.setState({ interactions: [] })
        new Promise((resolve, reject) => actions.fetchViewingFeedback({
          modelname: config.modelname,
          action: 'interactions',
          modelid: model.id,
          params: {
            lead: lead.id,
            get_all: 1,
            order_by: '-created'
          },
          resolve,
          reject
        })).then(r => {
          this.setState({ interactions: r })
        }).catch(e => {
          if (e.status !== 408) {
            console.error(e)
          }
        })
      }
    }
  }

  componentWillUnmount() {
    breakpoint.removeEventListener('change', this.toggleActions)
  }

  toggleActions(e) {
    if (e.matches && !this.state.showActions) {
      this.setState({ showActions: true })
    } else if (e.matches !== undefined && this.state.showActions) {
      this.setState({ showActions: false })
    }
  }

  handleSubmit(values, formik) {
    formik.setSubmitting(true)
    new Promise((resolve, reject) => this.props.actions.createLeadInteraction({
      modelid: this.props.lead.id,
      values,
      resolve,
      reject
    })).then(() => {
      formik.resetForm({})
      formik.setSubmitting(false)
      this.setState({ interaction: null, refresh: !this.state.refresh })
      this.props.actions.fetchAgain()
    }).catch(e => {
      handleSubmitError(e, formik, this.form)
      formik.setSubmitting(false)
    })
  }

  hasEditPermission() {
    const { lead, user } = this.props
    const requiredPermissions = [ 'leads_update', 'leads_update_own' ]
    if (user.permissions.includes('is_prop_data_user')) { return true }
    const hasEditOwnPermissions = requiredPermissions.filter(perm => perm.endsWith('_update_own'))
    const hasEditAllPermissions = requiredPermissions.filter(perm => perm.endsWith('_update'))
    if (lead) {
      if (hasPermission(hasEditAllPermissions, user.permissions)) {
        return true
      }
      if (hasPermission(hasEditOwnPermissions, user.permissions)) { // Agent doesn't match
        if (lead.agent !== user.agent.id) {
          const contact = getIn(lead, 'meta.contact')
          if (hasPermission([ 'leads_contacts_associated_agents_update' ], user.permissions) && contact.associated_agents && contact.associated_agents.includes(user.agent.id)) { // User is allowed to edit associated contacts' leads
            return true
          } else if (contact && contact.introduction_agent !== user.agent.id) {
            return false
          }
        }
        return true
      }
    }
    return false
  }

  render() {
    const { lead, model, user, match, modelname } = this.props
    const { settings } = this.props.cache
    if (!lead) {
      if (!this.state.showActions) {
        return null
      }
      return (
        <div className="leads-view no-leads">
          <span>Select a lead to view more details.</span>
        </div>
      )
    }
    return (
      <div className="leads-view">
        <div className='lead-interactions'>
          <Card
            background={true}
            bodyclass="nopadding"
            header={
              <>
                <h3 className="flex-heading">Lead Stage</h3>
                {!this.state.showActions ? (
                  <div className="lead-progress">
                    {lead.status === 'Inactive' ? (
                      <div className="lead-progress-item">
                        <div className={classNames('lead-progress-icon', { Archived: [ 'Inactive' ].includes(lead.status) })}><svg viewBox='0 0 32 32'><use href="/images/icons-16.svg#icon16-Check-Small" /></svg></div>
                        <div className="lead-progress-label">Archived</div>
                      </div>
                    ) : null}
                    {lead.stage === 'Sold' ? (
                      <div className="lead-progress-item">
                        <div className={classNames('lead-progress-icon', { Sold: [ 'Sold' ].includes(lead.stage) })}><svg viewBox='0 0 32 32'><use href="/images/icons-16.svg#icon16-Check-Small" /></svg></div>
                        <div className="lead-progress-label">{[ 'To Let', 'Holiday Letting' ].includes(model.listing_type) ? 'Rented' : 'Sold'}</div>
                      </div>
                    ) : null}
                    {lead.stage === 'Offer' ? (
                      <div className="lead-progress-item">
                        <div className={classNames('lead-progress-icon', { Offer: [ 'Offer', 'Sold' ].includes(lead.stage) })}><svg viewBox='0 0 32 32'><use href="/images/icons-16.svg#icon16-Check-Small" /></svg></div>
                        <div className="lead-progress-label">Offer</div>
                      </div>
                    ) : null}
                    {model.listing_type === 'To Let' && [ 'Rental Application', 'Lease Application' ].includes(lead.stage) && settings[user.agent.site.id].applications_addon ? (
                      <div className="lead-progress-item">
                        <div className={classNames('lead-progress-icon', { 'Lease-Application': [ 'Rental Application', 'Lease Application' ].includes(lead.stage) })}>{this.state.interactions.some(i => i.meta.rental_application?.status === 'Sent') ? <svg viewBox='0 0 32 32'><use href="/images/icons-16.svg#icon16-Clock" /></svg> : <svg viewBox='0 0 32 32'><use href="/images/icons-16.svg#icon16-Check-Small" /></svg>}</div>
                        <div className="lead-progress-label">Rental Application</div>
                      </div>
                    ) : null}
                    {lead.stage === 'Viewing' ? (
                      <div className="lead-progress-item">
                        <div className={classNames('lead-progress-icon', { Viewing: [ 'Viewing', 'Offer', 'Sold' ].includes(lead.stage) })}><svg viewBox='0 0 32 32'><use href="/images/icons-16.svg#icon16-Check-Small" /></svg></div>
                        <div className="lead-progress-label">Viewing</div>
                      </div>
                    ) : null}
                    {lead.stage === 'Contacted' ? (
                      <div className="lead-progress-item">
                        <div className={classNames('lead-progress-icon', { Contacted: [ 'Contacted', 'Viewing', 'Offer', 'Sold' ].includes(lead.stage) })}><svg viewBox='0 0 32 32'><use href="/images/icons-16.svg#icon16-Check-Small" /></svg></div>
                        <div className="lead-progress-label">Contacted</div>
                      </div>
                    ) : null}
                    {lead.stage === 'New' ? (
                      <div className="lead-progress-item">
                        <div className={classNames('lead-progress-icon', { New: [ 'New', 'Contacted', 'Viewing', 'Offer', 'Sold', 'Archived', 'Rental Application', 'Lease Application' ].includes(lead.stage) })}><svg viewBox='0 0 32 32'><use href="/images/icons-16.svg#icon16-Check-Small" /></svg></div>
                        <div className="lead-progress-label">New</div>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </>
            }
            body={this.state.showActions ? (
              <div className="lead-progress">
                <div className="lead-progress-item">
                  <div className={classNames('lead-progress-icon', { New: [ 'New', 'Contacted', 'Viewing', 'Offer', 'Sold', 'Archived', 'Rental Application', 'Lease Application' ].includes(lead.stage) })}><svg viewBox='0 0 32 32'><use href="/images/icons-16.svg#icon16-Check-Small" /></svg></div>
                  <div className="lead-progress-label">New</div>
                </div>
                <div className="lead-progress-divider" />
                <div className="lead-progress-item">
                  <div className={classNames('lead-progress-icon', { Contacted: [ 'Contacted', 'Viewing', 'Offer', 'Sold', 'Rental Application', 'Lease Application' ].includes(lead.stage) })}><svg viewBox='0 0 32 32'><use href="/images/icons-16.svg#icon16-Check-Small" /></svg></div>
                  <div className="lead-progress-label">Contacted</div>
                </div>
                <div className="lead-progress-divider" />
                <div className="lead-progress-item">
                  <div className={classNames('lead-progress-icon', { Viewing: [ 'Viewing', 'Offer', 'Sold' ].includes(lead.stage) || this.state.interactions.some(i => i.viewing) }) }><svg viewBox='0 0 32 32'><use href="/images/icons-16.svg#icon16-Check-Small" /></svg></div>
                  <div className="lead-progress-label">Viewing</div>
                </div>
                {model.listing_type === 'To Let' && settings[user.agent.site.id].applications_addon ? (
                  <>
                    <div className="lead-progress-divider" />
                    <div className="lead-progress-item">
                      <div className={classNames('lead-progress-icon', { 'Lease-Application': [ 'Rental Application', 'Lease Application' ].includes(lead.stage) || this.state.interactions.some(i => i.rental_application) })}>{this.state.interactions.some(i => i.meta.rental_application?.status === 'Sent') ? <svg viewBox='0 0 32 32'><use href="/images/icons-16.svg#icon16-Clock" /></svg> : <svg viewBox='0 0 32 32'><use href="/images/icons-16.svg#icon16-Check-Small" /></svg>}</div>
                      <div className="lead-progress-label">Rental Application</div>
                    </div>
                  </>
                ) : null}
                <div className="lead-progress-divider" />
                <div className="lead-progress-item">
                  <div className={classNames('lead-progress-icon', { Offer: [ 'Offer', 'Sold' ].includes(lead.stage) })}><svg viewBox='0 0 32 32'><use href="/images/icons-16.svg#icon16-Check-Small" /></svg></div>
                  <div className="lead-progress-label">Offer</div>
                </div>
                <div className="lead-progress-divider" />
                <div className="lead-progress-item">
                  <div className={classNames('lead-progress-icon', { Sold: [ 'Sold' ].includes(lead.stage) })}><svg viewBox='0 0 32 32'><use href="/images/icons-16.svg#icon16-Check-Small" /></svg></div>
                  <div className="lead-progress-label">{[ 'To Let', 'Holiday Letting' ].includes(model.listing_type) ? 'Rented' : 'Sold'}</div>
                </div>
                <div className="lead-progress-divider" />
                <div className="lead-progress-item">
                  <div className={classNames('lead-progress-icon', { Archived: [ 'Inactive' ].includes(lead.status) })}><svg viewBox='0 0 32 32'><use href="/images/icons-16.svg#icon16-Check-Small" /></svg></div>
                  <div className="lead-progress-label">Archived</div>
                </div>
              </div>
            ) : <div />}
          />
          {this.state.canEdit ? (
            <Card
              background={true}
              bodyclass="no-top-padding"
              header={
                <h3>Follow-up action</h3>
              }
              body={
                <>
                  <div className="interaction-selection">
                    <Button type="button" onClick={() => this.setState({ interaction: this.state.interaction === 'communication' ? null : 'communication' })} className={classNames('btn', { 'btn-subtle': this.state.interaction !== 'communication', 'btn-grey': this.state.interaction === 'communication' })}>Log Communication</Button>
                    <Button type="button" onClick={() => this.setState({ interaction: this.state.interaction === 'viewing' ? null : 'viewing' })} className={classNames('btn', { 'btn-subtle': this.state.interaction !== 'viewing', 'btn-grey': this.state.interaction === 'viewing' })}>Log Viewing</Button>
                    <Button type="button" onClick={() => this.setState({ interaction: this.state.interaction === 'offer' ? null : 'offer' })} className={classNames('btn', { 'btn-subtle': this.state.interaction !== 'offer', 'btn-grey': this.state.interaction === 'offer' })}>Log Offer</Button>
                    {(model.listing_type === 'To Let' && settings[user.agent.site.id].applications_addon && (user.permissions.includes('is_prop_data_user') || user.permissions.includes('applications_add'))) ? <Button type="button" onClick={() => this.setState({ interaction: this.state.interaction === 'rental_application' ? null : 'rental_application' })} className={classNames('btn', { 'btn-subtle': this.state.interaction !== 'rental_application', 'btn-grey': this.state.interaction === 'rental_application' })}>Send Rental Application</Button> : null}
                    <Button type="button" onClick={() => this.setState({ interaction: this.state.interaction === 'archive' ? null : 'archive' })} className={classNames('btn', { 'btn-subtle': this.state.interaction !== 'archive', 'btn-grey': this.state.interaction === 'archive' })}>Archive</Button>
                  </div>
                  {this.state.interaction && getIn(this.state, 'config.fields', []).length ? (
                    <Formik
                      initialValues={{
                        lead: lead.id,
                        type: this.state.interaction
                      }}
                      validateOnChange={false}
                      validateOnBlur={true}
                      onSubmit={this.handleSubmit}
                      enableReinitialize={true}
                    >{ formik => {
                        this.form = formik
                        return (
                          <CustomForm
                            component="div"
                            render={() => (
                              <>
                                <FieldGroup
                                  card={false}
                                  match={match}
                                  config={{
                                    fields: getIn(this.state, 'config.fields', [])
                                  }}
                                  fields={getIn(this.state, 'config.fields', [])}
                                />
                                <div className="interaction-buttons">
                                  {formik.isSubmitting ? (
                                    <Loader inline />
                                  ) : (
                                    <Button
                                      id="interaction-submit"
                                      tabIndex="-1"
                                      type="button"
                                      onClick={() => {
                                        formik.submitForm()
                                      }}
                                      disabled={formik.isSubmitting}
                                      className="btn btn-primary"
                                    >
                                      {this.state.interaction === 'rental_application' ? 'Send Application' : 'Submit'}
                                    </Button>
                                  )}
                                </div>
                              </>
                            )}
                          />
                        )
                      }}
                    </Formik>
                  ) : null}
                </>
              }
            />
          ) : null}
          {lead ? (
            <>
              <Card
                background
                classes="nopadding grey_25"
                header={(
                  <div className='lead-interaction-item-header'>
                    <div className='flex-container lead-interaction-item-top'>
                      <strong>{lead.meta.contact.first_name} {lead.meta.contact.last_name}</strong>
                      <div className='flex-container lead-interaction-item-date'>
                        <span>{valueFormat('datetime', lead.created)}</span>
                      </div>
                    </div>
                    <span>{lead.source}</span>
                  </div>
                )}
                body={(
                  <div className='lead-interaction-item-body'>
                    <span>{lead.message}</span>
                  </div>
                )}
              />
              {(model.model === 'residential' && model.listing_type === 'To Let' && settings[user.agent.site.id].applications_addon) ? (
                <Banner id="reos-banner" type="quality" heading="Get more with reOS" dismissable>
                  <a href="https://go.propdata.net/reos-book-demo" target="_blank" rel="noreferrer">Click here to find out more</a>
                </Banner>
              ) : null}
              <LeadInteractionsHistory
                refresh={this.state.refresh}
                interactionConfigs={this.configs}
                canEdit={this.state.canEdit}
                interactions={this.state.interactions}
                {...this.props}
              />
            </>
          ) : null}
        </div>
        <div className='lead-details'>
          <LeadDetails
            lead={lead}
            user={user}
            model={model}
            modelname={modelname}
          />
          <LeadLog
            interactionConfigs={this.configs} {...this.props}
          />
        </div>
      </div>
    )
  }
}

LeadInteractions.propTypes = {
  lead: PropTypes.object,
  match: PropTypes.object,
  user: PropTypes.object,
  model: PropTypes.object,
  cache: PropTypes.object,
  config: PropTypes.object,
  modelname: PropTypes.string,
  actions: PropTypes.object
}

export default LeadInteractions
